import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFeatureLocks,
  fetchStoreInfo,
  getAppConstants,
} from "./Redux/Actions";
import { getUrlParams } from "./Utils/getUrlParams";

//child components will use this context to fetch data.
export const ParentContext = createContext();

export default function ParentProvider(props) {
  const dispatch = useDispatch();
  const { themeData, storeData, domainDetails, subscriptionDetails } =
    useSelector((state) => ({
      themeData: state.themesReducer.displayTags,
      storeData: state.storeReducer.data,
      domainDetails: state.domainReducer.domainDetails,
      subscriptionDetails: state.themesReducer.subscriptionDetails,
    }));
  const urlParams = new URLSearchParams(window.location.search);
  const storeid = urlParams.get("storeid");
  const token = urlParams.get("token");
  const prevUrl = urlParams.get("prevUrl");
  const app_version = urlParams.get("app_version"); // android -> 4.4.6
  const app_version_code = urlParams.get("app_version_code"); // android -> 90
  const version_number = urlParams.get("version_number"); // ios -> 1.63
  const channel = urlParams.get("Channel") || urlParams.get("channel");
  const additionalParameters = `?token=${token}&storeid=${storeid}&app_version=${app_version}&app_version_code=${app_version_code}&version_number=${version_number}&channel=${channel}`;

  const payload = {
    storeid: Number(storeid),
    token,
    storeData,
    additionalParameters,
    app_version,
    app_version_code,
    version_number,
    channel,
  };

  React.useEffect(() => {
    if (token && token != "null") {
      dispatch(fetchStoreInfo(token));
      dispatch(fetchFeatureLocks());
    }
  }, []);

  useEffect(() => {
    const [isMobile] = getUrlParams(["isMobile"]);
    if (isMobile === "true") {
      dispatch(getAppConstants(true));
    }

    if (storeData?.store?.store_id) {
      dispatch(getAppConstants(false));
    }
  }, [storeData?.store?.store_id]);

  return (
    <ParentContext.Provider value={payload}>
      {props.children}
    </ParentContext.Provider>
  );
}
