import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import * as templatesTypes from "../ActionTypes";
import {
  savePDFHTML,
  saveWATemplates,
  saveWATemplateDetails,
  fetchWATemplates,
  fetchWATemplateDetails,
  showLoader,
  removeLoader,
} from "../Actions";
import { ENDPOINTS } from "../../Config/apiEndpoints";
import { ToastNotifyError } from "../../Components/Toast";

function* getWATemplates(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/shareBanner/${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveWATemplates(response.data.data));
    }
  } catch (err) {}
}

function* getWATemplateDetails(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/details?store_id=${actions.data.sid}&template_id=${actions.data.tid}`,
    });
    if (response && response.status) {
      yield put(saveWATemplateDetails(response.data.template_details));
      if (response.data.template_details.type === "pdf") {
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              sharePDF: true,
              pdfContent: response.data.template_details.html,
              tid: response.data.template_details.store_template_id,
            })
          );
      } else if (response.data.template_details.type === "wa") {
        window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              shareTemplate: true,
              tid: actions.data,
            })
          );
        window.location.href = `whatsapp://send?text=${encodeURIComponent(
          response.data.template_details.wa_text
        )}`;
      }
    }
  } catch (err) {}
}

function* shareTemplate(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/share/${actions.data}`,
    });
    if (response && response.data.status) {
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            shareTemplate: true,
            tid: actions.data,
          })
        );
      window.location.href = `whatsapp://send?text=${encodeURIComponent(
        response.data.wa_text
      )}`;
      yield put(removeLoader());
    } else {
      yield put(removeLoader());
    }
  } catch (err) {
    yield put(removeLoader());
  }
}

function* updateTemplate(actions) {
  try {
    const response = yield apiCall({
      method: "PUT",
      url: `/dotk/template/updateItemList`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(removeLoader());
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            createTemplate: true,
            tid: actions.data.store_template_id,
          })
        );
    } else {
      yield put(removeLoader());
    }
  } catch (err) {
    yield put(removeLoader());
  }
}

function* deleteTemplate(actions) {
  try {
    const response = yield apiCall({
      method: "DELETE",
      url: `/dotk/template/delete/${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(fetchWATemplates());
      yield put(
        fetchWATemplateDetails({
          sid: window.storeid,
          tid: actions.tid,
        })
      );
    }
  } catch (err) {}
}

function* fetchUserShoppingAccountDetails(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.getShoppingAccountDetailsApi(actions.data),
    });
    const extResponse = response.data?.google_shopping_account_details;
    const isGoogleShoppingEnabled =
      response.data?.status &&
      extResponse?.phone_verified &&
      extResponse?.claim_website == 1 &&
      extResponse?.product_sync == 1;
    actions.callback && actions.callback(isGoogleShoppingEnabled);
  } catch (err) {
    ToastNotifyError("Error!");
  }
}

export default function* root() {
  yield takeEvery(templatesTypes.FETCH_WA_TEMPLATES, getWATemplates);
  yield takeEvery(
    templatesTypes.FETCH_WA_TEMPLATE_DETAILS,
    getWATemplateDetails
  );
  yield takeEvery(templatesTypes.SHARE_TEMPLATE, shareTemplate);
  yield takeEvery(templatesTypes.UPDATE_TEMPLATE, updateTemplate);
  yield takeEvery(templatesTypes.DELETE_WA_TEMPLATE, deleteTemplate);
  yield takeEvery(
    templatesTypes.GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS,
    fetchUserShoppingAccountDetails
  );
}
