import { WEB_CONSOLE_URL } from "../constants";
import { WEB_CONSOLE_ROUTES } from "./constants";

export const goToWabaMarketingPage = (route, additionalParams = "") => {
  const compeleteHref = WEB_CONSOLE_URL + WEB_CONSOLE_ROUTES.WABA_MARKETING;
  if (additionalParams && additionalParams.includes("?"))
    additionalParams = additionalParams.replace("?", "&");
  window.location.assign((route ?? compeleteHref) + additionalParams);
};

export const goToWebConsole = (path, additionalParams = "") => {
  const compeleteHref = WEB_CONSOLE_URL + path;
  if (additionalParams && additionalParams.includes("?"))
    additionalParams = additionalParams.replace("?", "&");
  window.location.assign(compeleteHref + additionalParams);
};
