import * as actionTypes from "./../ActionTypes";

export const initPayment = (data, history) => {
  return {
    type: actionTypes.INIT_PAYMENT,
    data,
    history,
  };
};

export const pollPayment = (data, history) => {
  return {
    type: actionTypes.POLL_PAYMENT_STATUS,
    data,
    history,
  };
};

export const verifyPayment = (data, history) => {
  return {
    type: actionTypes.VERIFY_PAYMENT,
    data,
    history,
  };
};

export const fetchPaymentMethods = (data) => {
  return {
    type: actionTypes.FETCH_PAYMENT_METHODS,
    data,
  };
};

export const savePaymentMethods = (data) => {
  return {
    type: actionTypes.SAVE_PAYMENT_METHODS,
    data,
  };
};

export const savePaymentInfo = (data) => {
  return {
    type: actionTypes.SAVE_PAYMENT_INFORMATIONS,
    data,
  };
};

export const savePrePaymentData = (data) => {
  return {
    type: actionTypes.SAVE_PRE_PAYMENT_DATA,
    data,
  };
};

export const savePostPaymentData = (data) => {
  return {
    type: actionTypes.SAVE_POST_PAYMENT_DATA,
    data,
  };
};

export const initLazyPayPayment = (data, router) => {
  return {
    type: actionTypes.INIT_LAZY_PAY_PAYMENT,
    data,
    router,
  };
};

export const verifyLazyPayOtp = (data, history) => {
  return {
    type: actionTypes.VERIFY_LAZY_PAY_OTP,
    data,
    history,
  };
};

export const setEmiOption = (data) => {
  return {
    type: actionTypes.SET_EMI_OPTION,
    data,
  };
};

export const getEmiOptionForCard = (data) => {
  return {
    type: actionTypes.GET_EMI_OPTIONS_FOR_CARD,
    data,
  };
};

export const saveEmiOptionForCardData = (data) => {
  return {
    type: actionTypes.SAVE_EMI_OPTION_FOR_CARD_DATA,
    data,
  };
};

export const togglePaymentSuccessModalMethod = (status) => {
  return {
    type: actionTypes.TOGGLE_PAYMENT_SUCCESS_MODAL,
    status,
  };
};

export const verifyGstForPurchase = (data, callback) => {
  return {
    type: actionTypes.VERIFY_GST_FOR_PURCHASE,
    data,
    callback,
  };
};

export const setAvailableUpiPaymentOptions = (data) => {
  return {
    type: actionTypes.SET_AVAILABLE_UPI_PAYMENT_OPTIONS,
    data,
  };
};
