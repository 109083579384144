import { MOBILE_EVENT_TARGET } from "../constants";

export const logAndroid = (data) => {
  if (window.Android) {
    window.Android.showAndroidLog(data);
  }
};
export const trackAppEvents = (eventName, additionalData) => {
  if (window.Android) {
    window.Android.sendData(
      JSON.stringify({
        trackEventData: true,
        eventName,
        additionalData,
      })
    );
  } else {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          trackEventData: true,
          eventName,
          additionalData,
        })
      );
  }
};

/**
 *
 * @param {*} data
 * @param {number} target , 0(default) -> both, 1 -> Android, 2 -> iOS
 */
export const sendDataToApp = (data, target = MOBILE_EVENT_TARGET.BOTH) => {
  const forAndroid = [
    MOBILE_EVENT_TARGET.BOTH,
    MOBILE_EVENT_TARGET.ANDROID,
  ].includes(target);
  const forIos = [MOBILE_EVENT_TARGET.BOTH, MOBILE_EVENT_TARGET.IOS].includes(
    target
  );

  if (window.Android && forAndroid)
    window.Android.sendData(JSON.stringify(data));
  if (window.ReactNativeWebView && forIos)
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
};

export const goBackToApp = () => {
  if (window.Android) {
    window.Android.onNativeBackPress();
  } else {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify({ goBack: true }));
  }
};
