import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./../ActionTypes";
import { ToastNotifyError, ToastNotifySuccess } from "./../../Components/Toast";
import apiCall from "./../../Services/api";
import { saveStoreInfo } from "./../Actions";
import { ENDPOINTS } from "../../Config/apiEndpoints";
import { CONSTANTS, setLocalState } from "../../Utils/storage";

function* generateOTP(actions) {
  try {
    const otpResponse = yield apiCall({
      method: "POST",
      url: ENDPOINTS.LOGIN.generateOTP(actions.data.data),
      showDefaultErrorMsg: false,
    });
    if (otpResponse && otpResponse.data.status) {
      actions && actions.data.callback();
      ToastNotifySuccess("OTP sent Successfully");
    } else {
      ToastNotifyError("Error !");
    }
  } catch (err) {
    console.log(err);
  }
}

function* verifyOpenOTP(actions) {
  try {
    const verifyResponse = yield apiCall({
      method: "POST",
      url: ENDPOINTS.LOGIN.verifyOpenOTP,
      data: {
        otp: actions.data.otp,
        phone: actions.data.phone,
        source: window?.location?.href || "",
      },
    });
    if (verifyResponse && verifyResponse.data.status) {
      const parsedData = JSON.parse(verifyResponse.data.data);
      setLocalState(CONSTANTS.LOCAL.TEMP_TOKEN, parsedData.auth_token);
      if (actions.data && !actions.data.from) {
        setLocalState("token", parsedData.auth_token);
        window.auth_token = parsedData.auth_token;
        yield put(saveStoreInfo(parsedData));
        ToastNotifySuccess("Successfully Verified");
      }
      actions.data &&
        actions.data.callback &&
        actions.data.callback(true, parsedData);
    } else {
      actions.data &&
        actions.data.callback &&
        actions.data.callback(false, false);
      ToastNotifyError(verifyResponse.data.message);
    }
  } catch (err) {
    actions.data &&
      actions.data.callback &&
      actions.data.callback(false, err?.data?.message);
    ToastNotifyError(err?.data?.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.GENERATE_OTP, generateOTP);
  yield takeEvery(actionTypes.VERIFY_OPEN_OTP, verifyOpenOTP);
}
