import * as templatesTypes from "../ActionTypes";

const initialState = {
  data: "",
  templates: [],
  templateDetails: {},
  templateDetailsItems: [],
};

const templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case templatesTypes.SAVE_WA_TEMPLATES:
      return { ...state, templates: action.data };
    case templatesTypes.EDIT_WA_TEMPLATE:
      const updateProps = [...action.data];
      return { ...state, templateDetailsItems: updateProps };
    case templatesTypes.SAVE_WA_TEMPLATE_DETAILS:
      return {
        ...state,
        templateDetails: action.data,
        templateDetailsItems:
          action.data.item_list && action.data.item_list.length
            ? action.data.item_list
            : ["", "", "", ""],
      };
    default:
      return state;
  }
};

export default templatesReducer;
