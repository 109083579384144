import * as appTypes from "../ActionTypes";

const initialState = {
  data: {
    contactList: {},
    help: {},
    noOrders: {},
  },
};

const AppEssentialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case appTypes.SAVE_CONTACT_LIST:
      return { ...state, data: { ...state.data, contactList: action.data } };
    case appTypes.SAVE_HELP_PAGE_ESSENTIALS:
      return { ...state, data: { ...state.data, help: action.data } };
    case appTypes.SAVE_NOORDERS_PAGE_ESSENTIALS:
      return { ...state, data: { ...state.data, noOrders: action.data } };
    default:
      return state;
  }
};

export default AppEssentialsReducer;
