import * as adsTypes from "../ActionTypes";

export const getAdsList = (callback) => {
  return {
    type: adsTypes.FETCH_ADS_LIST,
    callback,
  };
};

export const saveAdsList = (data) => {
  return {
    type: adsTypes.SAVE_ADS_LIST,
    data,
  };
};

export const searchAdLocations = (data) => {
  return {
    type: adsTypes.SEARCH_AD_LOCATIONS,
    data,
  };
};

export const saveSearchedAdLocations = (data) => {
  return {
    type: adsTypes.SAVE_SEARCHED_AD_LOCATIONS,
    data,
  };
};

export const getAdKeywords = (data) => {
  return {
    type: adsTypes.FETCH_AD_KEYWORDS,
    data,
  };
};

export const saveAdKeywords = (data) => {
  return {
    type: adsTypes.SAVE_AD_KEYWORDS,
    data,
  };
};

export const saveAd = (data, callback) => {
  return {
    type: adsTypes.SAVE_AD,
    data,
    callback
  };
}

export const createAd = (data) => {
  return {
    type: adsTypes.CREATE_AD,
    data
  }
}

export const initiateAdPurchase = (data, callback) => {
  return {
    type: adsTypes.INITIATE_AD_PURCHASE,
    data,
    callback
  }
}

export const getAdDetails = (data, callback) => {
  return {
    type: adsTypes.FETCH_AD_DETAILS,
    data,
    callback
  }
}

export const saveAdDetails = (data, callback) => {
  return {
    type: adsTypes.SAVE_AD_DETAILS,
    data
  }
}

export const updateAdState = (data) => {
  return {
    type: adsTypes.UPDATE_AD_STATE,
    data
  }
}