import * as commonTypes from "../ActionTypes";
import { LAZYPAY_AUTH_MODAL_VIEWS } from "../../Utils/constants";

const initialState = {
  isItemModalVisible: false,
  alertFlowSystem: false,
  productLoaderId: null,
  collectionsSelection: "full",
  categoriesSelection: "full",
  productsSelection: "full",
  variant: {
    variantModal: false,
    chooseRepeatModal: false,
    selectedVariantsModal: false,
  },
  detailedItem: null,
  lazyPayAuthModal: {
    show: false,
    view: LAZYPAY_AUTH_MODAL_VIEWS.OTP,
  },
  featureLocks: {},
  helpSection: {},
  appConstants: {},
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.SET_ADD_PRODUCT_MODAL_VISIBLE:
      return { ...state, isItemModalVisible: action.data };
    case commonTypes.SET_ALERT_FLOW_SYSTEM:
      return { ...state, alertFlowSystem: action.data };
    case commonTypes.SAVE_PRODUCT_LOADER_ID:
      return {
        ...state,
        productLoaderId: action.data,
      };
    case commonTypes.SAVE_DETAILED_ITEM_BY_ID:
      return {
        ...state,
        detailedItem: action.data,
      };
    case commonTypes.SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: false,
          chooseRepeatModal: action.data,
          selectedVariantsModal: false,
        },
      };
    case commonTypes.SET_VARIANT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: action.data,
          chooseRepeatModal: false,
          selectedVariantsModal: false,
        },
      };
    case commonTypes.SET_SELECTED_VARIANT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: false,
          chooseRepeatModal: false,
          selectedVariantsModal: action.data,
        },
      };
    case commonTypes.TOGGLE_LAZYPAY_AUTH_MODAL:
      return {
        ...state,
        lazyPayAuthModal: {
          show: action.data.show,
          view: action.data.view || LAZYPAY_AUTH_MODAL_VIEWS.OTP,
        },
      };
    case commonTypes.COLLECTION_PARTIAL_SELECTION:
      return {
        ...state,
        collectionsSelection: "partial",
      };
    case commonTypes.CATEGORY_PARTIAL_SELECTION:
      return {
        ...state,
        categoriesSelection: "partial",
      };
    case commonTypes.PRODUCT_PARTIAL_SELECTION:
      return {
        ...state,
        productsSelection: "partial",
      };
    case commonTypes.SAVE_FEATURE_LOCKS:
      return {
        ...state,
        featureLocks: action.data,
      };
    case commonTypes.SAVE_HELP_SECTION_ROUTES:
      return {
        ...state,
        helpSection: action.data,
      };
    case commonTypes.SAVE_APP_CONSTANTS:
      return {
        ...state,
        appConstants: action.data || {},
      };
    default:
      return state;
  }
};

export default CommonReducer;
