import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import * as analyticsTypes from "../ActionTypes";
import { fetchStoreInfo, saveStoreInfo } from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { ENDPOINTS } from "../../Config/apiEndpoints";

function* getStoreInfo(actions) {
  try {
    const userResponse = yield apiCall({
      method: "POST",
      data: {
        auth_token: actions.data,
      },
      url: `/dotk/merchant/authenticate`,
    });
    if (userResponse && userResponse.data.status) {
      yield put(saveStoreInfo(userResponse.data));
      actions.callback && actions.callback(userResponse.data);
    }
  } catch (err) {}
}

function* updateIsSetupFlag(actions) {
  try {
    const userResponse = yield apiCall({
      method: "POST",
      url: `/dotk/store/updateIsSetup`,
    });
    if (userResponse && userResponse.data.status) {
      ToastNotifySuccess("Request sent successfully!");
      yield put(fetchStoreInfo(actions.data));
    } else {
      ToastNotifyError("Unable to connect, Try again later!");
    }
  } catch (err) {}
}

function* updateStoreServiceFlag(actions) {
  try {
    const userResponse = yield apiCall({
      method: "PATCH",
      data: actions.data,
      url: ENDPOINTS.COMMONS.UPDATE_STORE_SERVICES_FLAG,
    });
    if (userResponse && userResponse.data.status) {
      
      actions.callback?.({status: true});
    } else {
      actions.callback?.({status: false});
    }
  } catch (err) {
    actions.callback?.({status: false});
  }
}

export default function* root() {
  yield takeEvery(analyticsTypes.FETCH_STOREINFO, getStoreInfo);
  yield takeEvery(analyticsTypes.UPDATE_ISSETUP_FLAG, updateIsSetupFlag);
  yield takeEvery(analyticsTypes.UPDATE_STORE_SERVICE_FLAG, updateStoreServiceFlag);
}
