import React from "react";
import ReactDOM from "react-dom";
// LIBRARIES - REDUX
import { Provider } from "react-redux";
// CONTAINERS
import App from "./App";
// REDUX
import store from "./Redux/Store";
import "./index.css";

function startApp() {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
}

startApp()