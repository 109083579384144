import { put, takeEvery } from "redux-saga/effects";
import apiCall from "./../../Services/api";
import { GET_ALL_UTILITIES_DATA } from "../ActionTypes";
import { ToastNotifyError } from "../../Components/Toast";
import { saveAllUtilitiesData } from "../Actions/utilities";
import { getAppOS } from "../../Utils/getAppOs";

function* getUtilityPageDetails(actions) {
  try {
    const utilitiesPageRes = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/onboard/getUtilitiesInfo`,
      parseToJson: true,
      app_os: getAppOS()?.os || "webview",
    });
    if (utilitiesPageRes && utilitiesPageRes.data?.status) {
      yield put(saveAllUtilitiesData(utilitiesPageRes.data?.data || {}));
      actions.callback?.(true);
    } else {
      ToastNotifyError(utilitiesPageRes.data.message || "Something went bad!");
      actions.callback?.(false);
    }
  } catch (err) {}
}

export default function* root() {
  yield takeEvery(GET_ALL_UTILITIES_DATA, getUtilityPageDetails);
}
