import { getUrlParams } from "./getUrlParams";

/**
 * Perform task based on version checks for each mobile OS
 * @param {number} iosVersion
 * @param {number} androidVersionCode
 * @param {function} callback
 */
export function setVersionCheck(iosVersion, androidVersionCode, callback) {
  const [versionNumber, appVersionCode] = getUrlParams([
    "version_number",
    "app_version_code",
  ]);
  if (versionNumber >= iosVersion) {
    callback && callback();
  }
  if (appVersionCode >= androidVersionCode) {
    callback && callback();
  }
}
