import {
  FETCH_MY_EARNINGS_DETAILS,
  FETCH_PARTNER_PROGRAM_DETAILS,
  FETCH_PARTNER_PROGRAM_STATUS,
  JOIN_PARTNER_PROGRAM,
  SAVE_MY_EARNINGS_DETAILS,
  SAVE_PARTNER_PROGRAM_DETAILS,
  SAVE_PARTNER_PROGRAM_STATUS,
} from "../ActionTypes";

export const joinPartnerProgram = (data, callback) => {
  return {
    type: JOIN_PARTNER_PROGRAM,
    data,
    callback,
  };
};

export const fetchPartnerProgramStatus = (data, callback) => {
  return {
    type: FETCH_PARTNER_PROGRAM_STATUS,
    data,
    callback,
  };
};

export const savePartnerProgramStatus = (data) => {
  return {
    type: SAVE_PARTNER_PROGRAM_STATUS,
    data,
  };
};

export const fetchPartnerProgramDetails = (data, callback) => {
  return {
    type: FETCH_PARTNER_PROGRAM_DETAILS,
    data,
    callback,
  };
};

export const savePartnerProgramDetails = (data) => {
  return {
    type: SAVE_PARTNER_PROGRAM_DETAILS,
    data,
  };
};

export const fetchMyEarningsDetails = (data, callback) => {
  return {
    type: FETCH_MY_EARNINGS_DETAILS,
    data,
    callback,
  };
};

export const saveMyEarningsDetails = (data) => {
  return {
    type: SAVE_MY_EARNINGS_DETAILS,
    data,
  };
};
