import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import {
  ANDROID_GOOGLE_PAY_INTENT_LINK,
  ANDROID_PAYTM_INTENT_LINK,
  ANDROID_PHONEPE_INTENT_LINK,
  ANDROID_WHATSAPP_INTENT_LINK,
  IOS_GOOGLE_PAY_INTENT_LINK,
  IOS_PAYTM_INTENT_LINK,
  IOS_PHONEPE_INTENT_LINK,
  IOS_WHATSAPP_INTENT_LINK,
} from "../../Config/paymentLinks";
import apiCall from "../../Services/api";
import * as actionTypes from "../ActionTypes";
import {
  isPremiumSet,
  removeLoader,
  saveEmiOptionForCardData,
  savePaymentMethods,
  savePostPaymentData,
  showLoader,
  toggleLazyPayAuthModal,
} from "../Actions";
import { getUPIPaymentDetails, getPackageName } from "../../Utils/common";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import { getMobileOperatingSystem } from "../../Utils/operatingSystem";
import store from "./../Store";
import { sendDataToApp } from "../../Utils/androidEvents";
import { getPurchaseTypeFromText } from "./../../Utils/common";
import {
  DOMAIN_PURCHASE,
  LAZYPAY_AUTH_MODAL_VIEWS,
  ROUTES,
} from "../../Utils/constants";
import {
  CONSTANTS,
  getSessionState,
  removeSessionState,
} from "../../Utils/storage";
import { PAYMENT_METHODS } from "../../constants";
import { ENDPOINTS } from "../../Config/apiEndpoints";
import { redirectAfterPaymentVerifyFails } from "../../Utils/paymentHelper";

const urlParams = new URLSearchParams(window.location.search);
const app_version = urlParams.get("app_version");

//payment initialization
function* initPayment(actions) {
  try {
    const store_id = getSessionState("storeid");
    const token = getSessionState("token");
    const selectedPaymentMethod = actions.data?.paymentMethod;
    yield put(showLoader());
    const paymentResponse = yield apiCall({
      method: "POST",
      url: ENDPOINTS.PAYMENT.initiatePayment,
      data: actions.data,
      parseToJson: true,
    });
    if (paymentResponse?.data?.status) {
      yield put(removeLoader());
      let response = paymentResponse.data?.data;
      let intentLink = "";

      if (selectedPaymentMethod === "phonepe") {
        intentLink =
          getMobileOperatingSystem() === "Android"
            ? ANDROID_PHONEPE_INTENT_LINK
            : IOS_PHONEPE_INTENT_LINK;
      } else if (selectedPaymentMethod === "googlepay") {
        intentLink =
          getMobileOperatingSystem() === "Android"
            ? ANDROID_GOOGLE_PAY_INTENT_LINK
            : IOS_GOOGLE_PAY_INTENT_LINK;
      } else if (selectedPaymentMethod === "paytmupi") {
        intentLink =
          getMobileOperatingSystem() === "Android"
            ? ANDROID_PAYTM_INTENT_LINK
            : IOS_PAYTM_INTENT_LINK;
      } else if (selectedPaymentMethod === "whatsapp") {
        intentLink =
          getMobileOperatingSystem() === "Android"
            ? ANDROID_WHATSAPP_INTENT_LINK
            : IOS_WHATSAPP_INTENT_LINK;
      }

      if (
        getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE) ===
          "domain-marketing" ||
        getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE) ===
          DOMAIN_PURCHASE
      ) {
        if (
          actions.data.paymentMethod === "phonepe" ||
          actions.data.paymentMethod === "googlepay" ||
          actions.data.paymentMethod === "paytmupi" ||
          actions.data.paymentMethod === "whatsapp"
        ) {
          intentLink = intentLink.replace("{deeplink}", response.data.url);
          sessionStorage.setItem(
            CONSTANTS.LOCAL.TRANSACTION_ID,
            response.txnId
          );
          setTimeout(() => {
            window.location.href = intentLink;
          }, 400);
        } else {
          if (["paytm", "airtel"].includes(response.pg)) {
            setTimeout(() => {
              window.location.href = response.data.url;
            }, 400);
          } else {
            yield put(savePostPaymentData(response));
          }
        }
      } else if (
        selectedPaymentMethod === "phonepe" ||
        selectedPaymentMethod === "googlepay" ||
        selectedPaymentMethod === "paytmupi" ||
        selectedPaymentMethod === "whatsapp"
      ) {
        intentLink = intentLink.replace("{deeplink}", response.data.url);

        if (app_version && parseFloat(app_version) > 4) {
          //for new versions
          sendDataToApp({
            openUPI: true,
            ...getUPIPaymentDetails(response.data.url),
            packageName: getPackageName(selectedPaymentMethod),
          });
        } else {
          //for older versions backward compatibility
          sendDataToApp({
            redirectBrowser: true,
            redirectURL: `${config.webviewURL}redirectpayment?paymentInfo=${intentLink}`,
            data: `${config.webviewURL}redirectpayment?paymentInfo=${intentLink}`,
          });
        }
        sessionStorage.setItem(CONSTANTS.LOCAL.TRANSACTION_ID, response.txnId);
      } else {
        if (response.pg === "paytm") {
          window.location.href = response.data.url;
        } else {
          yield put(
            savePostPaymentData({ ...response, isInitialRedirect: true })
          );
        }
      }
      actions.history.replace(
        `${ROUTES.PAYMENT_PROCESSING}/${response.txnId}?token=${token}&storeid=${store_id}&app_version=${app_version}&method=${selectedPaymentMethod}`
      );
    } else {
      yield put(removeLoader());
      removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
      removeSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
      ToastNotifyError("Payment Failed.");
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError(err);
    removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
    removeSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
  }
}

function* initLazyPayPayment(actions) {
  try {
    yield put(showLoader());
    const paymentResponse = yield apiCall({
      method: "POST",
      url: ENDPOINTS.PAYMENT.initiateLazypayPayment,
      data: actions.data,
      parseToJson: true,
    });

    if (paymentResponse && paymentResponse.data.status) {
      const response = paymentResponse.data.data;
      sessionStorage.setItem(CONSTANTS.LOCAL.TRANSACTION_ID, response.txnId);
      if (response.is_eligible) {
        if (response.is_otp_required) {
          yield put(
            toggleLazyPayAuthModal({
              show: true,
              view: LAZYPAY_AUTH_MODAL_VIEWS.OTP,
            })
          );
        } else {
          yield put(
            toggleLazyPayAuthModal({
              show: true,
              // view: LAZYPAY_AUTH_MODAL_VIEWS.ELIGIBLE,
              view: LAZYPAY_AUTH_MODAL_VIEWS.NONELIGIBLE,
            })
          );
        }
      } else {
        yield put(
          toggleLazyPayAuthModal({
            show: true,
            view: LAZYPAY_AUTH_MODAL_VIEWS.NONELIGIBLE,
          })
        );
      }
    }
  } catch (error) {
    // ToastNotifyError("Something went bad! try again later.");
  } finally {
    yield put(removeLoader());
    removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
    removeSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
  }
}

function* verifyLazyPayOtp(actions) {
  try {
    yield put(showLoader());
    const store_id = getSessionState("storeid");
    const token = getSessionState("token");
    const txnID = sessionStorage.getItem(CONSTANTS.LOCAL.TRANSACTION_ID);
    const paymentInfo = store.getState().paymentReducer.paymentInfo;
    const verifyResponse = yield apiCall({
      method: "POST",
      url: ENDPOINTS.PAYMENT.verifyLazypayOtp,
      data: actions.data,
    });

    if (verifyResponse && verifyResponse.data.status === "success") {
      yield put(
        toggleLazyPayAuthModal({
          show: false,
          view: LAZYPAY_AUTH_MODAL_VIEWS.OTP,
        })
      );
      actions.history.push(
        `${ROUTES.PAYMENT_PROCESSING}/${txnID}?token=${token}&storeid=${store_id}&app_version=${app_version}&method=${paymentInfo.paymentMethod}`
      );
    } else {
      ToastNotifyError(verifyResponse.data.message);
    }
  } catch (error) {
  } finally {
    yield put(removeLoader());
    removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
    removeSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
  }
}

//fetch all payment methods
function* fetchPaymentMethods(actions) {
  try {
    yield put(showLoader());
    const paymentMethodsResponse = yield apiCall({
      method: "POST",
      // url: `${config.old_base_url}/payment/fetchPaymentModes/${actions.data}`,
      url: `${config.base_url}/dotk/vo1/paymentOptions/getPaymentModesByMerchantId`,
      data: actions.data,
      parseToJson: true,
    });
    if (paymentMethodsResponse && paymentMethodsResponse.data) {
      yield put(removeLoader());
      yield put(savePaymentMethods(paymentMethodsResponse.data.data));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError(
      "Something went bad while fetching payments methods! Try after some time."
    );
  }
}

function* fetchEmiOptionsForCard(actions) {
  try {
    yield put(showLoader());
    const fetchEmiResponse = yield apiCall({
      method: "POST",
      url: `${config.base_url}${ENDPOINTS.EMI_API.GET_EMI_OPTIONS_FOR_CARD}`,
      data: actions.data,
      parseToJson: true,
    });
    if (fetchEmiResponse && fetchEmiResponse.data) {
      yield put(removeLoader());
      yield put(saveEmiOptionForCardData(fetchEmiResponse.data.data));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError(
      "Something went bad while fetching payments methods! Try after some time."
    );
  }
}

function* setEmiOptionForCard(actions) {
  try {
    yield put(showLoader());
    const paymentMethodsResponse = yield apiCall({
      method: "POST",
      // url: `${config.old_base_url}/payment/fetchPaymentModes/${actions.data}`,
      url: `${config.base_url}/dotk/vo1/paymentOptions/getPaymentModesByMerchantId`,
      data: actions.data,
      parseToJson: true,
    });
    if (paymentMethodsResponse && paymentMethodsResponse.data) {
      yield put(removeLoader());
      yield put(savePaymentMethods(paymentMethodsResponse.data.data));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError(
      "Something went bad while fetching payments methods! Try after some time."
    );
  }
}

/*
 *payment polling in case of UPI
 */
function* pollPayment(actions) {
  const prePaymentData = store.getState().paymentReducer.prePaymentData;
  const store_id = getSessionState("storeid");
  const token = getSessionState("token");
  const additionalParams = `?token=${token}&storeid=${store_id}&app_version=${app_version}`;
  const premiumPurchaseTypeIsTheme = getPurchaseTypeFromText(
    getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE)
  );

  try {
    const pollPaymentResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}${ENDPOINTS.PAYMENT.pollPaymentStatus(
        actions.data
      )}`,
      signal: actions.signal,
      parseToJson: true,
    });
    if (pollPaymentResponse?.data?.status) {
      yield put(removeLoader());
      sessionStorage.removeItem(CONSTANTS.LOCAL.TRANSACTION_ID);
      /**
       * If this is a subscription purchase using coupon, then redirect to order list from here.
       */

      if (premiumPurchaseTypeIsTheme === 999) {
        ToastNotifySuccess("Payment Success!");
        sendDataToApp({
          redirectOrderList: true,
          redirectHomePage: true,
        });
      } else {
        yield put(
          isPremiumSet({
            store_id: Number(store_id),
            type_id: prePaymentData.theme_id,
            type: premiumPurchaseTypeIsTheme,
            text: prePaymentData.domain,
            apply_type: "paid",
          })
        );
      }
    } else {
      yield put(removeLoader());
      sessionStorage.removeItem(CONSTANTS.LOCAL.TRANSACTION_ID);
      redirectAfterPaymentVerifyFails(
        premiumPurchaseTypeIsTheme,
        additionalParams,
        actions.history
      );
      ToastNotifyError("Payment failed");
    }
    sendDataToApp({
      refreshToken: true,
    });
  } catch (err) {
    yield put(removeLoader());
    redirectAfterPaymentVerifyFails(
      premiumPurchaseTypeIsTheme,
      additionalParams,
      actions.history
    );
    removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
    removeSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
    ToastNotifyError("Payment failed");
  }
}

//payment verification in case of paytm
function* verifyPayment(actions) {
  const paymentInfo = store.getState().paymentReducer.paymentInfo;
  const store_id = getSessionState("storeid");
  const token = getSessionState("token");
  const additionalParams = `?token=${token}&storeid=${store_id}&app_version=${app_version}`;
  const prePaymentData = JSON.parse(sessionStorage.getItem("prePaymentData"));
  const premiumPurchaseTypeIsTheme = getPurchaseTypeFromText(
    getSessionState(CONSTANTS.SESSION.PREMIUM_PURCHASE_TYPE)
  );

  try {
    yield put(showLoader());
    const verifyPaymentResponse = yield apiCall({
      method: "GET",
      url: `${config.base_url}${ENDPOINTS.PAYMENT.verifyPayment(actions.data)}`,
      parseToJson: true,
    });
    if (verifyPaymentResponse?.data?.status) {
      yield put(removeLoader());
      yield put(
        isPremiumSet({
          store_id: Number(store_id),
          type_id: prePaymentData?.theme_id,
          type: premiumPurchaseTypeIsTheme,
          text: prePaymentData?.domain,
          apply_type: "paid",
        })
      );
    } else {
      yield put(removeLoader());
      ToastNotifyError(`${verifyPaymentResponse.data.status} : Payment failed`);
      redirectAfterPaymentVerifyFails(
        premiumPurchaseTypeIsTheme,
        additionalParams,
        actions.history
      );
    }
    sendDataToApp({
      refreshToken: true,
    });
    sessionStorage.removeItem("card-json");
  } catch (err) {
    sessionStorage.removeItem("card-json");
    yield put(removeLoader());
    ToastNotifyError("Payment failed");
    redirectAfterPaymentVerifyFails(
      premiumPurchaseTypeIsTheme,
      additionalParams,
      actions.history
    );
    removeSessionState(CONSTANTS.SESSION.IS_DOMAIN_RENEWAL);
    removeSessionState(CONSTANTS.SESSION.IS_THEME_UPSELL);
  }
}

function* verifyGstForPurchase(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: config.base_url + ENDPOINTS.PAYMENT.verifyGSTForPurchase,
    });
    if (response && response?.data?.status) {
      actions.callback(true, response?.data?.text);
    } else {
      actions.callback(false, "");
    }
    yield put(removeLoader());
  } catch (err) {
    actions.callback(false, "");
    yield put(removeLoader());
  }
}

export default function* root() {
  yield takeEvery(actionTypes.INIT_PAYMENT, initPayment);
  yield takeEvery(actionTypes.FETCH_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeEvery(actionTypes.POLL_PAYMENT_STATUS, pollPayment);
  yield takeEvery(actionTypes.VERIFY_PAYMENT, verifyPayment);
  yield takeEvery(actionTypes.INIT_LAZY_PAY_PAYMENT, initLazyPayPayment);
  yield takeEvery(actionTypes.VERIFY_LAZY_PAY_OTP, verifyLazyPayOtp);
  yield takeEvery(actionTypes.GET_EMI_OPTIONS_FOR_CARD, fetchEmiOptionsForCard);
  yield takeEvery(actionTypes.SET_EMI_OPTION, setEmiOptionForCard);
  yield takeEvery(actionTypes.VERIFY_GST_FOR_PURCHASE, verifyGstForPurchase);
}
