import * as actionTypes from "../ActionTypes";

const initialState = {
  data: {
    primary_result: {},
    results: [],
  },
  searchData: {
    primary_result: {},
    results: [],
    searchStatus: null,
    searchedStringBE: "",
  },
  domainDetails: null,
  domainAnalytics: {
    domains: [],
    count: null,
  },
  domainPurchasedModal: false,
  domainList: [],
  domainRenewalDetails: {},
  domainRenewedModal: false,
  domainCart: {
    cart_id: 0,
    type: 0,
    amount: 0,
    discounted_amount: 0,
    items: [],
  },
  selectedDomainsInCart: {},
  latestDomainPurchase: {},
  domainText: "",
  bannerInfo: null,
};

const DomainReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_DOMAIN_LIST:
      return {
        ...state,
        data: action.data,
      };
    case actionTypes.SAVE_SEARCH_DOMAIN_LIST:
      return {
        ...state,
        searchData: action.data,
      };
    case actionTypes.SAVE_DOMAIN_DETAILS:
      return {
        ...state,
        domainDetails: action.data,
      };
    case actionTypes.SAVE_DOMAIN_ANALYTICS:
      return {
        ...state,
        domainAnalytics: {
          domains: action.data.domains,
          count: action.data.count,
        },
      };
    case actionTypes.TOGGLE_DOMAIN_PURCHASED_MODAL:
      return {
        ...state,
        domainPurchasedModal: action.data,
      };
    case actionTypes.SAVE_ALL_DOMAIN_LIST:
      return {
        ...state,
        domainList: action.data,
      };
    case actionTypes.SAVE_DOMAIN_RENEWAL_DETAILS:
      return {
        ...state,
        domainRenewalDetails: action.data,
      };
    case actionTypes.TOGGLE_DOMAIN_RENEWED_MODAL:
      return {
        ...state,
        domainRenewedModal: action.data,
      };
    case actionTypes.SAVE_DOMAIN_CART:
      return {
        ...state,
        domainCart: action.data,
      };
    case actionTypes.SAVE_LATEST_DOMAIN_PURCHASE_DETAILS:
      return {
        ...state,
        latestDomainPurchase: action.data,
      };
    case actionTypes.SAVE_DOMAIN_TEXT:
      return {
        ...state,
        domainText: action.data,
      };
    case actionTypes.SET_DOMAIN_BANNER_INFO:
      return {
        ...state,
        bannerInfo: action.data || null,
      };
    default:
      return state;
  }
};

export default DomainReducer;
