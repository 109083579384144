import { SAVE_UTILITIES_DATA } from "../ActionTypes";

const initialState = {
  bannerList: [],
  categoryList: [],
  featureList: [],
  latest_releases_list: [],
};

const utilitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_UTILITIES_DATA:
      return {
        ...state,
        bannerList: action.data?.banner_list || [],
        categoryList: action.data?.category_list || [],
        featureList: action.data?.feature_list || {},
        latestReleasesList: action.data?.latest_releases_list || [],
      };
    default:
      return state;
  }
};

export default utilitiesReducer;
