import * as actionTypes from "../ActionTypes";

export function fetchDisplayTags(data) {
  return {
    type: actionTypes.FETCH_DISPLAY_TAGS,
    data,
  };
}

export function saveDisplayTags(data) {
  return {
    type: actionTypes.SAVE_DISPLAY_TAGS,
    data,
  };
}

export function searchDisplayTags(data) {
  return {
    type: actionTypes.SEARCH_DISPLAY_TAGS,
    data,
  };
}

export function fetchDisplayThemes(data) {
  return {
    type: actionTypes.FETCH_DISPLAY_THEMES,
    data,
  };
}

export function saveDisplayThemes(data) {
  return {
    type: actionTypes.SAVE_DISPLAY_THEMES,
    data,
  };
}

export function emptyDisplayThems() {
  return {
    type: actionTypes.EMPTY_DISPLAY_THEMES,
  };
}

export function saveSearchTags(data) {
  return {
    type: actionTypes.SAVE_SEARCH_TAGS,
    data,
  };
}

export function applyTheme(data, callback) {
  return {
    type: actionTypes.SET_THEME,
    data,
    callback,
  };
}

export function updateThemeData(data) {
  return {
    type: actionTypes.UPDATE_THEME_DATA,
    data,
  };
}

export function fetchSubscriptionDetails(data, callback) {
  return {
    type: actionTypes.GET_SUBSCRIPTION_DETAILS,
    data,
    callback,
  };
}

export function saveSubscriptionDetails(data) {
  return {
    type: actionTypes.SAVE_SUBSCRIPTION_DETAILS,
    data,
  };
}

export function deactivateStoreTheme(data, callback) {
  return {
    type: actionTypes.DEACTIVATE_STORE_THEME,
    data,
    callback,
  };
}

export function fetchAllStoreThemes(data, callback) {
  return {
    type: actionTypes.FETCH_ALL_STORE_THEMES,
    data,
    callback,
  };
}

export function saveAllStoreThemes(data) {
  return {
    type: actionTypes.SAVE_ALL_STORE_THEMES,
    data,
  };
}
