import { put, takeEvery } from "redux-saga/effects";
import config from "./../../Config";
import apiCall from "../../Services/api";
import { saveLocalizedTxt } from "../Actions/localization";
import { FETCH_LOCALIZED_TXTS } from "./../ActionTypes";

function* fetchLocalizedTxts(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getInAppText`,
    })
    if (response && response.status) {
      yield put(saveLocalizedTxt(response.data.data));
    }
  } catch (err) {}
}

export default function* root() {
  yield takeEvery(FETCH_LOCALIZED_TXTS, fetchLocalizedTxts);
}