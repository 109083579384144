export const URL_REGEX_DOMAIN = new RegExp(
  /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
);

export const SPECIAL_CHARACTERS_REGEX = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g;

export const NON_SPECIAL_CHARACTERS_REGEX = /[^a-zA-Z0-9]/g;

export const EMAIL_NAME_REGEX = /[^0-9a-zA-Z.]/g; // allows dot, but no other special character

export const EMAIL_VALIDATION_REGEX =
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  export const SIMPLE_EMAIL_VALIDATION = /^[a-zA-Z0-9+_.-]+$/;

export const SPECIAL_CHAR = /[^a-zA-Z0-9]/g;
export const EMAIL_VALIDATION = /^[a-zA-Z0-9+_.-]+$/;
export const EMAIL_ACCEPTANCE_CHARACTERS = /[^0-9a-zA-Z.]/g; // allows dot, but no other special character

