import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import * as appEssentialsTypes from "../ActionTypes";
import { removeLoader, saveHelpEssentials, saveNoOrdersEssentials, showLoader } from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";

function* fetchHelpEssentials(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getHelpSectionText`,
    });
    if (response && response.data.status) {
      yield put(removeLoader());
      yield put(saveHelpEssentials(response.data.help_page_keys));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch language!");
  }
}

function* fetchNoOrdersEssentials(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/template/getNoorderScreenText`,
    });
    if (response && response.data.status) {
      yield put(removeLoader());
      yield put(saveNoOrdersEssentials(response.data.no_order_screen));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch language!");
  }
}

export default function* root() {
  yield takeEvery(
    appEssentialsTypes.FETCH_HELP_PAGE_ESSENTIALS,
    fetchHelpEssentials
  );
  yield takeEvery(
    appEssentialsTypes.FETCH_NOORDERS_PAGE_ESSENTIALS,
    fetchNoOrdersEssentials
  );
}
