import { put, takeEvery } from "redux-saga/effects";
import * as workspaceTypes from "../ActionTypes";
import apiCall from "../../Services/api";
import {
  removeLoader,
  savePrePaymentData,
  saveWorkspaceDetails,
  saveWorkspaceEmailUser,
  saveWorkspaceOfferPrice,
  saveWorkspacePriceDetails,
  saveWorkspaceRenewalDetails,
  showLoader,
} from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import history from "../../Utils/history";
import { sendDataToApp } from "../../Utils/androidEvents";
import { setPrePaymentData, setPurchaseType } from "../../Utils/paymentHelper";
import { ENDPOINTS } from "../../Config/apiEndpoints";

const ERROR_MSG = {
  CANNOT_PURCHASE_EMAILS:
    "Cannot purchase emails currently. Please try again later.",
  WRKS_INFO_NOT_FOUND:
    "Workspace information not found. Please try again later.",
  EMAIL_EXIST: "EmailID already exists",
  WRKS_PRICE_INFO_NOT_FOUND:
    "Workspace price information not found. Please try again later.",
  CANNOT_RENEW_EMAILS: "Cannot renew emails currently. Please try again later.",
};

function* initWorkspacePurchase(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.WORKSPACE.initiateWorkspacePurchase(actions.isUpsell),
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPurchaseType("google-workspace");
      setPrePaymentData(response?.data?.data);
      sessionStorage.setItem("emailPurchaseCount", actions.data.count);
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    }
    sendDataToApp({
      refreshToken: true,
    });
    yield put(removeLoader());
  } catch (err) {
    sendDataToApp({
      refreshToken: true,
    });
    yield put(removeLoader());
  }
}

function* createEmailAccount(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.WORKSPACE.provisionAndAddUserMail,
      data: actions.data,
      parseToJson: true,
    });
    if (response) {
      yield put(removeLoader());
      actions && actions.callback(response?.data?.status, response?.data);
    }
  } catch (err) {
    yield put(removeLoader());
    actions.callback && actions.callback(false, err?.message);
  }
}

function* getWorkspaceEmailUser(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "POST",
      // url: `/dotk/vm1/premium/getWorkspaceEmailUser`,
      url: ENDPOINTS.WORKSPACE.getWorkspaceLicense(actions.data),
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveWorkspaceEmailUser(response?.data?.data));
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
  }
}

function* getWorkspaceDetails(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.WORKSPACE.getWorkspaceDetails(actions.data.text),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      const tempArray = [...response?.data?.data.workspace_user_list];
      const { primaryAccount, associateAccounts } = tempArray.reduce(
        (acc, curr) => {
          if (curr?.is_primary) {
            acc.primaryAccount = curr;
          } else {
            acc.associateAccounts.push(curr);
          }
          return acc;
        },
        { primaryAccount: {}, associateAccounts: [] }
      );
      yield put(
        saveWorkspaceDetails({
          workspace_info: response?.data?.data.workspace_info,
          workspacePrimaryUser: primaryAccount,
          workspaceUsersList: associateAccounts,
          allWorkspaceUsers: [{ ...primaryAccount }, ...associateAccounts],
        })
      );
      actions.callback &&
        actions.callback(true, [{ ...primaryAccount }, ...associateAccounts]);
    } else {
      yield put(saveWorkspaceDetails({}));
      actions.callback && actions.callback(false, []);
    }
    yield put(removeLoader());
    actions.callback && actions.callback(false, []);
  } catch (err) {
    yield put(removeLoader());
  }
}

function* getWorkspacePricingDetails(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.WORKSPACE.workspacePriceDetails(actions),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      !actions.isUpsell
        ? yield put(saveWorkspacePriceDetails(response.data.data))
        : yield put(saveWorkspaceOfferPrice(response.data.data));

      actions.callback && actions.callback(response?.data?.status);
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
  }
}

function* getWorkspaceRenewalDetails(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.WORKSPACE.workspaceRenewalDetails(actions.data),
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(saveWorkspaceRenewalDetails(response.data.data));
      actions.callback && actions.callback(true);
    }
  } catch (err) {
    console.log(err);
    actions.callback && actions.callback(false);
  }
}

function* initiateWorkspaceRenewal(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.WORKSPACE.initiateWorkspaceRenewal,
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      setPurchaseType("google-workspace");
      setPrePaymentData(response?.data?.data);
      sessionStorage.setItem("emailPurchaseCount", actions.data.count);
      yield put(savePrePaymentData(response?.data?.data));
      sendDataToApp({
        refreshToken: true,
      });
      actions.callback && actions.callback(response?.data?.status);
    } else {
      actions.callback && actions.callback(false);
    }
  } catch (err) {
    sendDataToApp({
      refreshToken: true,
    });
    console.log(err);
    actions.callback && actions.callback(false);
  }
}

export default function* root() {
  yield takeEvery(
    workspaceTypes.INITIATE_WORKSPACE_PURCHASE,
    initWorkspacePurchase
  );
  yield takeEvery(workspaceTypes.CREATE_EMAIL_ACCOUNT, createEmailAccount);
  yield takeEvery(
    workspaceTypes.GET_WORKSPACE_EMAIL_USER,
    getWorkspaceEmailUser
  );
  yield takeEvery(workspaceTypes.GET_WORKSPACE_DETAILS, getWorkspaceDetails);
  yield takeEvery(
    workspaceTypes.GET_WORKSPACE_PRICE_DETAILS,
    getWorkspacePricingDetails
  );
  yield takeEvery(
    workspaceTypes.GET_WORKSPACE_RENEWAL_DETAILS,
    getWorkspaceRenewalDetails
  );
  yield takeEvery(
    workspaceTypes.INITIATE_WORKSPACE_RENEWAL,
    initiateWorkspaceRenewal
  );
}
