import { put, takeEvery } from "redux-saga/effects";
import { ToastNotifyError } from "../../Components/Toast";
import { sendDataToApp } from "../../Utils/androidEvents";
import { setPrePaymentData, setPurchaseType } from "../../Utils/paymentHelper";
import { removeLoader, savePrePaymentData, showLoader } from "../Actions";
import apiCall from "../../Services/api";
import { GET_PAYMENT_DETAIL_FOR_BRAND_WEBSITE } from "../ActionTypes";

function* fetchBrandWebsitePaymentDetails(actions) {
    yield put(showLoader());
    sendDataToApp({
      showLoader: true,
    });
    try {
      const response = yield apiCall({
        method: "POST",
        url: `/dotk/vm1/premium/storeTheme`,
        data: actions.data,
        parseToJson:true,
      });
      if (response && response?.data?.status) {
        setPurchaseType("brand-website");
        if (response && response?.data?.data?.is_payable) {
          setPrePaymentData(response?.data?.data?.payment_details);
          yield put(savePrePaymentData(response?.data?.data?.payment_details));
          actions.callback && actions.callback(true);
        } else {
          sendDataToApp({
            refreshToken: true,
          });
          // ToastNotifySuccess("Theme set successfully");
          actions.callback && actions.callback(false, response?.data);
        }
      } else {
        ToastNotifyError(response?.data?.message);
      }
      sendDataToApp({
        stopLoader: true,
      });
      yield put(removeLoader());
    } catch (err) {
      sendDataToApp({
        stopLoader: true,
      });
      sendDataToApp({
        refreshToken: true,
      });
      yield put(removeLoader());
    }
  }

  export default function* root() {
    yield takeEvery(GET_PAYMENT_DETAIL_FOR_BRAND_WEBSITE, fetchBrandWebsitePaymentDetails);  
  }