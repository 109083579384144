import * as storeTypes from "../ActionTypes";

const initialState = {
  data: {
    notLoaded: true,
    store: {
      owner: {
        phone: "",
        email_id: ""
      },
      store_info: {
        name: "",
        store_url: ""
      },
      address: {
        address_1: "",
        address_2: "",
        pincode: ""
      }
    },
  },
};

const StoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case storeTypes.SAVE_STOREINFO:
      return { ...state, data: action.data };
    case storeTypes.UPDATE_THEME_DATA: {
      let storeKey = state.data.store;
      return {
        ...state,
        data: { store: { ...storeKey, theme: action.data } },
      };
    }
    case storeTypes.RESET_WORKSPACE_OFFER_EXPIRY_TIME: {
      let storeKey = JSON.parse(JSON.stringify(state.data.store));
      return {
        ...state,
        data: {
          store: {
            ...storeKey,
            constants: {
              ...storeKey.constants,
              workspace_domain_upsell_expiry_time: "",
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default StoreReducer;
