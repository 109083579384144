import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import * as analyticsTypes from "../ActionTypes";
import { saveAllReward, saveClaimedRewards, fetchAllReward } from "../Actions";
import { sendDataToApp } from "../../Utils/androidEvents";

function* fetchRewardDetails(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/reward/getAllRewards/${actions.data}`,
    });
    if (response && response.data.status) {
      yield put(saveAllReward(response.data));
    }
  } catch (err) {}
}

function* fetchClaimedRewards(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/reward/getClaimedRewards?store_id=${actions.data}&page=1`,
    });
    if (response && response.data.status) {
      yield put(saveClaimedRewards(response.data));
    }
  } catch (err) {}
}

function* claimReward(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/reward/claimReward`,
      data: actions.data,
    });
    if (response && response.data.status) {
      yield put(fetchAllReward(actions.storeid));
      sendDataToApp({
        scratchClaimed: true,
      });
    }
  } catch (err) {}
}

export default function* root() {
  yield takeEvery(analyticsTypes.FETCH_ALL_REWARD, fetchRewardDetails);
  yield takeEvery(analyticsTypes.FETCH_CLAIMED_REWARDS, fetchClaimedRewards);
  yield takeEvery(analyticsTypes.CLAIM_REWARD, claimReward);
}
