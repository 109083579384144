export const ROUTE_CONSTANT = {
  THEME_HISTORY: "/theme-history",
  THEME_DISCOVER: "/theme-discover",
  DOMAIN_EXPLORE: "/domain-explore",
  DOMAIN_CART: "/domain-cart",
  RENEW_DOMAIN: "/domain-renewal-details",
  WHATSAPP_MARKETING_WEBVIEW: "/webview/whatsapp-business-marketing",
  BUSINESS_CARDS_ROUTE: "business-cards",
  QR_ROUTE: "qr",
};

export const HELP_SECTION_URL = "https://digitalshowroom.in/help-section/";