import * as actionTypes from "../ActionTypes";

const initialState = {
  subscriptionPurchaseDetailsUsingCoupons: null,
};

const CouponReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON:
      return {
        ...state,
        subscriptionPurchaseDetailsUsingCoupons: action.data || null,
      };
    default:
      return state;
  }
};

export default CouponReducer;
