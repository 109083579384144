import React, { useLayoutEffect } from "react";
import "./CommonStyles/helpers.scss";
import "./CommonStyles/reset.scss";
import "./CommonStyles/tachyons/index.scss";
import "./CommonStyles/color.scss";
import AppRouter from "./routes";
import AlertFlow from "./Components/AlertFlow";
import VariantModal from "./Components/VariantModal";
import VariantSelectedListModal from "./Components/VariantSelectedListModal";
import VariantChooseRepeatModal from "./Components/VariantChooseRepeatModal";
import ParentProvider from "./parentProvider";
import ErrorHandling from "./errorHandling";
import clevertap from "clevertap-web-sdk";
import DomainRenewalSuccessModal from "./Components/DomainRenewalSuccessModal";
import useAvailablePaymentOptions from "./Hooks/useAvailablePaymentOptions";

export default function AppRoot() {
  useAvailablePaymentOptions();
  useLayoutEffect(() => {
    clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
    clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
    clevertap.spa = true;
    clevertap.init(process.env.CLEVERTAP_ID);
  }, []);

  return (
    <ErrorHandling>
      <ParentProvider>
        <AppRouter />
        <AlertFlow />
        <VariantModal />
        <VariantSelectedListModal />
        <VariantChooseRepeatModal />
        <DomainRenewalSuccessModal />
      </ParentProvider>
    </ErrorHandling>
  );
}
