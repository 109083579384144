// Login
export const GENERATE_OTP = "AUTH/GENERATE_OTP";
export const VERIFY_OPEN_OTP = "AUTH/VERIFY_OPEN_OTP";

//Templates
export const FETCH_WA_TEMPLATES = "TEMPLATE/FETCH_WA_TEMPLATES";
export const SAVE_WA_TEMPLATES = "TEMPLATE/SAVE_WA_TEMPLATES";
export const FETCH_WA_TEMPLATE_DETAILS = "TEMPLATE/FETCH_WA_TEMPLATE_DETAILS";
export const SAVE_WA_TEMPLATE_DETAILS = "TEMPLATE/SAVE_WA_TEMPLATE_DETAILS";
export const DELETE_WA_TEMPLATE = "TEMPLATE/DELETE_WA_TEMPLATE";
export const EDIT_WA_TEMPLATE = "TEMPLATE/EDIT_WA_TEMPLATE";
export const SHARE_TEMPLATE = "TEMPLATE/SHARE_TEMPLATE";
export const UPDATE_TEMPLATE = "TEMPLATE/UPDATE_TEMPLATE";
export const GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS =
  "GOOGLE_SHOOPING/GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS";

//Loader
export const SHOW_LOADER = "LOADER/SHOW_LOADER";
export const REMOVE_LOADER = "LOADER/REMOVE_LOADER";

//Localization
export const FETCH_LOCALIZED_TXTS = "LOCALIZATION/FETCH_LOCALIZED_TXTS";
export const SAVE_LOCALIZED_TXTS = "LOCALIZATION/SAVE_LOCALIZED_TXTS";

//ANALYTICS
export const LOG_EVENT = "TEMPLATE/LOG_EVENT";
export const LOG_SESSION = "TEMPLATE/LOG_SESSION";
export const LOG_INSTALL = "TEMPLATE/LOG_INSTALL";

//Rewards
export const FETCH_ALL_REWARD = "REWARDS/FETCH_ALL_REWARD";
export const SAVE_ALL_REWARD = "REWARDS/SAVE_REWARD_DETAILS";
export const FETCH_CLAIMED_REWARDS = "REWARDS/FETCH_CLAIMED_REWARDS";
export const SAVE_CLAIMED_REWARDS = "REWARDS/SAVE_OTHER_REWARDS";
export const CLAIM_REWARD = "REWARDS/CLAIM_REWARD";

//catalog
export const FETCH_CATALOG_LIST = "CATALOG/FETCH_CATALOG_LIST";
export const SAVE_CATALOG_LIST = "CATALOG/SAVE_CATALOG_LIST";
export const ADD_CATALOG_ITEM = "CATALOG/ADD_CATALOG_ITEM";
export const FETCH_CATALOG_CATEGORIES = "CATALOG/FETCH_CATALOG_CATEGORIES";
export const SAVE_CATALOG_CATEGORIES = "CATALOG/SAVE_CATALOG_CATEGORIES";
export const FETCH_CATALOG_ITEMS_BY_CATEGORYID =
  "CATALOG/FETCH_CATALOG_ITEMS_BY_CATEGORYID";
export const FETCH_CATALOG_ITEMS_BASIC_DETAILS_BY_CATEGORYID =
  "CATALOG/FETCH_CATALOG_ITEMS_BASIC_DETAILS_BY_CATEGORYID";
export const SAVE_REORDERED_CATEGORY = "CATALOG/SAVE_REORDERED_CATEGORY";
export const FETCH_CATALOG_ITEM_BY_ID = "CATALOG/FETCH_CATALOG_ITEM_BY_ID";
export const SAVE_REORDERED_ITEMS = "CATALOG/SAVE_REORDERED_ITEMS";
export const FETCH_SEARCHED_ITEMS = "CATALOG/FETCH_SEARCHED_ITEMS";
export const SAVE_SEARCHED_ITEMS = "CATALOG/SAVE_SEARCHED_ITEMS";
export const FETCH_COLLECTION_CATEGORY_ITEMS =
  "CATALOG/FETCH_COLLECTION_CATEGORY_ITEMS";
export const SET_COLLECTION_CATEGORY_ITEMS =
  "CATALOG/SET_COLLECTION_CATEGORY_ITEMS";
export const UPDATE_TOTAL_CATALOG_ITEMS_COUNT =
  "CATALOG/UPDATE_TOTAL_CATALOG_ITEMS_COUNT";
export const GET_CATALOG_BY_STORE_ID = "CATALOG/GET_CATALOG_BY_STORE_ID";
export const SET_STORE_CATALOG_DATA = "CATALOG/SET_CATALOG_BY_STORE_ID";
export const GET_COLLECTION_CATEGORY = "CATALOG/GET_COLLECTION_CATEGORY";
export const SET_COLLECTION_CATEGORY = "CATALOG/SET_COLLECTION_CATEGORY";
export const SET_CUSTOM_CATLOG = "CATALOG/SET_CUSTOM_CATALOG";
export const SAVE_CUSTOM_CATLOG = "CATALOG/SAVE_CUSTOM_CATALOG";
export const SHARE_CATALOG = "CATALOG/SHARE_CATALOG";
export const GET_ORDER_CART_BY_ID = "CATALOG/GET_ORDER_CART_BY_ID";
export const FETCH_ITEMS_BY_ITEM_IDS = "CATALOG/FETCH_ITEMS_BY_ITEM_IDS";
export const GET_GST_BILLING_DETAILS = "GET_GST_BILLING_DETAILS";
export const SAVE_GST_BILLING_DETAILS = "SAVE_GST_BILLING_DETAILS";
export const SAVE_SEO_DETAILS_BY_TYPE = "SAVE_SEO_DETAILS_BY_TYPE";
export const SET_SHOW_SEO_RECOMMENDATIONS = "SET_SHOW_SEO_RECOMMENDATIONS";
export const FETCH_SEO_DETAILS_BY_TYPE = "FETCH_SEO_DETAILS_BY_TYPE";

//cart
export const ADD_TO_CART = "CATALOG/ADD_TO_CART";

//App Essentials
export const SAVE_CONTACT_LIST = "CATALOG/SAVE_CONTACT_LIST";

//order
export const CREATE_ORDER = "ORDER/CREATE_ORDER";
export const SAVE_ORDER_INFO = "ORDER/SAVE_ORDER_INFO";

//userinfo
export const FETCH_STOREINFO = "USER/FETCH_STOREINFO";
export const SAVE_STOREINFO = "USER/SAVE_STOREINFO";
export const UPDATE_ISSETUP_FLAG = "USER/UPDATE_ISSETUP_FLAG";
export const UPDATE_STORE_SERVICE_FLAG = "USER/UPDATE_STORE_SERVICE_FLAG";

//common
export const SET_ADD_PRODUCT_MODAL_VISIBLE =
  "COMMON/SET_ADD_PRODUCT_MODAL_VISIBLE";
export const IS_PREMIUM_SET = "COMMON/IS_PREMIUM_SET";
export const FETCH_FEATURE_LOCKS = "USER/FETCH_FEATURE_LOCKS";
export const SAVE_FEATURE_LOCKS = "USER/SAVE_FEATURE_LOCKS";
export const SET_ALERT_FLOW_SYSTEM = "COMMON/SET_ALERT_FLOW_SYSTEM";
export const SAVE_PRODUCT_LOADER_ID = "COMMON/SAVE_PRODUCT_LOADER_ID";
export const SAVE_DETAILED_ITEM_BY_ID = "COMMON/SAVE_DETAILED_ITEM_BY_ID";
export const SET_VARIANT_MODEL_VISIBILITY =
  "COMMON/SET_VARIANT_MODEL_VISIBILITY";
export const SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY =
  "COMMON/SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY";
export const SET_SELECTED_VARIANT_MODEL_VISIBILITY =
  "COMMON/SET_SELECTED_VARIANT_MODEL_VISIBILITY";
export const TOGGLE_LAZYPAY_AUTH_MODAL = "COMMON/TOGGLE_LAZYPAY_AUTH_MODAL";
export const COLLECTION_PARTIAL_SELECTION =
  "COMMON/COLLECTION_PARTIAL_SELECTION";
export const CATEGORY_PARTIAL_SELECTION = "COMMON/CATEGORY_PARTIAL_SELECTION";
export const PRODUCT_PARTIAL_SELECTION = "COMMON/PRODUCT_PARTIAL_SELECTION";
export const GET_HELP_SECTION_ROUTES = "COMMON/GET_HELP_SECTION_ROUTES";
export const SAVE_HELP_SECTION_ROUTES = "COMMON/SAVE_HELP_SECTION_ROUTES";
export const GET_APP_CONSTANTS = "COMMON/GET_APP_CONSTANTS";
export const SAVE_APP_CONSTANTS = "COMMON/SAVE_APP_CONSTANTS";

//business creatives
export const FETCH_CREATIVE_CATEGORY_LIST =
  "CREATIVES/FETCH_CREATIVE_CATEGORY_LIST";
export const SAVE_CREATIVE_CATEGORY_LIST =
  "CREATIVES/SAVE_CREATIVE_CATEGORY_LIST";
export const FETCH_CREATIVE_TEMPLATE_LIST =
  "CREATIVES/FETCH_CREATIVE_TEMPLATE_LIST";
export const SAVE_CREATIVE_TEMPLATE_LIST =
  "CREATIVES/SAVE_CREATIVE_TEMPLATE_LIST";
export const FETCH_CREATIVE_BACKGROUNDS_LIST =
  "CREATIVES/FETCH_CREATIVE_BACKGROUNDS_LIST";
export const SAVE_CREATIVE_BACKGROUNDS_LIST =
  "CREATIVES/SAVE_CREATIVE_BACKGROUNDS_LIST";

//for social media creatives

export const FETCH_SOCIAL_TAG_LIST = "SOCIAL_CREATIVES/FETCH_SOCIAL_TAG_LIST";
export const SAVE_SOCIAL_TAG_LIST = "SOCIAL_CREATIVES/SAVE_SOCIAL_TAG_LIST";
export const FETCH_SOCIAL_TEMPLATE_LIST =
  "SOCIAL_CREATIVES/FETCH_SOCIAL_TEMPLATE_LIST";
export const SAVE_SOCIAL_TEMPLATE_LIST =
  "SOCIAL_CREATIVES/SAVE_SOCIAL_TEMPLATE_LIST";
export const EMPTY_SOCIAL_TEMPLATE_LIST =
  "SOCIAL_CREATIVES/EMPTY_SOCIAL_TEMPLATE_LIST";
export const UPDATE_FAVOURITE_CREATIVE =
  "SOCIAL_CREATIVES/UPDATE_FAVOURITE_CREATIVE";
export const UPDATE_FAVOURITE_CREATIVE_ITEM =
  "SOCIAL_CREATIVES/UPDATE_FAVOURITE_CREATIVE_ITEM";
export const FETCH_FAVOURITE_SOCIAL_TEMPLATE_LIST =
  "SOCIAL_CREATIVES/FETCH_ALL_FAVOURITE_SOCIAL_TEMPLATE_LIST";
export const SAVE_SHARED_TEMPLATE = "CREATIVE/SAVE_SHARED_TEMPLATE";

//APP ESSENTIALS
export const FETCH_HELP_PAGE_ESSENTIALS = "HELP/FETCH_HELP_PAGE_ESSENTIALS";
export const SAVE_HELP_PAGE_ESSENTIALS = "HELP/SAVE_HELP_PAGE_ESSENTIALS";
export const FETCH_NOORDERS_PAGE_ESSENTIALS =
  "NOORDERS/FETCH_NOORDERS_PAGE_ESSENTIALS";
export const SAVE_NOORDERS_PAGE_ESSENTIALS =
  "NOORDERS/SAVE_NOORDERS_PAGE_ESSENTIALS";

//Analytics
export const RECORD_ANALYTICS_EVENT = "ANALYITCS/RECORD_ANALYTICS_EVENT";

//Themes
export const FETCH_DISPLAY_TAGS = "THEMES/FETCH_DISPLAY_TAGS";
export const SAVE_DISPLAY_TAGS = "THEMES/SAVE_DISPLAY_TAGS";
export const SAVE_SEARCH_TAGS = "THEMES/SAVE_SEARCH_TAGS";
export const SEARCH_DISPLAY_TAGS = "THEMES/SEARCH_DISPLAY_TAGS";
export const FETCH_DISPLAY_THEMES = "THEMES/FETCH_DISPLAY_THEMES";
export const SAVE_DISPLAY_THEMES = "THEMES/SAVE_DISPLAY_THEMES";
export const EMPTY_DISPLAY_THEMES = "THEMES/EMPTY_DISPLAY_THEMES";
export const SET_THEME = "THEMES/SET_THEME";
export const UPDATE_THEME_DATA = "THEMES/UPDATE_THEME_DATA";
export const GET_SUBSCRIPTION_DETAILS = "THEMES/GET_SUBSCRIPTION_DETAILS";
export const SAVE_SUBSCRIPTION_DETAILS = "THEMES/SAVE_SUBSCRIPTION_DETAILS";
export const DEACTIVATE_STORE_THEME = "THEMES/DEACTIVATE_STORE_THEME";
export const FETCH_ALL_STORE_THEMES = "THEMES/FETCH_ALL_STORE_THEMES";
export const SAVE_ALL_STORE_THEMES = "THEMES/SAVE_ALL_STORE_THEMES";

// PAYMENTS
export const INIT_PAYMENT = "PAYMENT/INIT_PAYMENT";
export const FETCH_PAYMENT_METHODS = "PAYMENT/FETCH_PAYMENT_METHODS";
export const SAVE_PAYMENT_METHODS = "PAYMENT/SAVE_PAYMENT_METHODS";
export const POLL_PAYMENT_STATUS = "PAYMENT/POLL_PAYMENT_STATUS";
export const SAVE_PAYMENT_INFORMATIONS = "PAYMENT/SAVE_PAYMENT_INFORMATIONS";
export const SAVE_PRE_PAYMENT_DATA = "PAYMENT/SAVE_PRE_PAYMENT_DATA";
export const VERIFY_PAYMENT = "PAYMENT/VERIFY_PAYMENT";
export const SAVE_POST_PAYMENT_DATA = "PAYMENT/SAVE_POST_PAYMENT_DATA";
export const INIT_LAZY_PAY_PAYMENT = "PAYMENT/INIIATE_LAZY_PAY_PAYMENT";
export const VERIFY_LAZY_PAY_OTP = "PAYMENT/VERIFY_LAZY_PAY_OTP";
export const INITIATE_WABA_PURCHASE = "PAYMENT/INITIATE_WABA_PURCHASE";
export const INITIATE_SOCIAL_SHARE_PURCHASE =
  "PAYMENT/INITIATE_SOCIAL_SHARE_PURCHASE";
export const GET_EMI_OPTIONS_FOR_CARD = "PAYMENT/GET_EMI_OPTIONS_FOR_CARD";
export const SET_EMI_OPTION = "PAYMENT/SET_EMI_OPTION";
export const VERIFY_GST_FOR_PURCHASE = "PAYMENT/VERIFY_GST_FOR_PURCHASE";
export const SAVE_EMI_OPTION_FOR_CARD_DATA =
  "PAYMENT/SAVE_EMI_OPTION_FOR_CARD_DATA";
export const SET_AVAILABLE_UPI_PAYMENT_OPTIONS =
  "PAYMENT/SET_AVAILABLE_UPI_PAYMENT_OPTIONS";
/**   Payment Succcess Modal */
export const TOGGLE_PAYMENT_SUCCESS_MODAL =
  "PAYMENT/TOGGLE_PAYMENT_SUCCESS_MODAL";

//domain
export const SEARCH_DOMAIN = "DOMAIN/SEARCH_DOMAIN";
export const SEARCH_OPEN_DOMAIN = "DOMAIN/SEARCH_OPEN_DOMAIN";
export const DEFAULT_SEARCH_DOMAIN = "DOMAIN/DEFAULT_SEARCH_DOMAIN";
export const SAVE_DOMAIN_LIST = "DOMAIN/SAVE_DOMAIN_LIST";
export const SAVE_SEARCH_DOMAIN_LIST = "DOMAIN/SAVE_SEARCH_DOMAIN_LIST";
export const INITIATE_DOMAIN_PURCHASE = "DOMAIN/INITIATE_DOMAIN_PURCHASE";
export const INITIATE_DOMAIN_RENEWAL = "DOMAIN/INITIATE_DOMAIN_RENEWAL";
export const GET_DOMAIN_DETAILS = "DOMAIN/GET_DOMAIN_DETAILS";
export const SAVE_DOMAIN_DETAILS = "DOMAIN/SAVE_DOMAIN_DETAILS";
export const INITIATE_DOMAIN_SETUP_PURCHASE =
  "DOMAIN/INITIATE_DOMAIN_SETUP_PURCHASE";
export const GET_DOMAIN_ANALYTICS = "DOMAIN/GET_DOMAIN_ANALYTICS";
export const SAVE_DOMAIN_ANALYTICS = "DOMAIN/SAVE_DOMAIN_ANALYTICS";
export const TOGGLE_DOMAIN_PURCHASED_MODAL =
  "DOMAIN/TOGGLE_DOMAIN_PURCHASED_MODAL";
export const TOGGLE_DOMAIN_RENEWED_MODAL = "DOMAIN/TOGGLE_DOMAIN_RENEWED_MODAL";

export const FETCH_DOMAIN_CART = "DOMAIN/FETCH_DOMAIN_CART";
export const SAVE_DOMAIN_CART = "DOMAIN/SAVE_DOMAIN_CART";
export const UPDATE_DOMAIN_CART = "DOMAIN/UPDATE_DOMAIN_CART";
export const INITIATE_DOMAIN_CART_PURCHASE =
  "DOMAIN/INITIATE_DOMAIN_CART_PURCHASE";
export const GET_LATEST_DOMAIN_PURCHASE_DETAILS =
  "DOMAIN/GET_LATEST_DOMAIN_PURCHASE_DETAILS";
export const SAVE_LATEST_DOMAIN_PURCHASE_DETAILS =
  "DOMAIN/SAVE_LATEST_DOMAIN_PURCHASE_DETAILS";
export const CHECK_DOMAIN_AVAILABLE = "DOMAIN/CHECK_DOMAIN_AVAILABLE";
export const SAVE_DOMAIN_TEXT = "DOMAIN/SAVE_DOMAIN_TEXT";
// domain renewal
export const FETCH_ALL_DOMAIN_LIST = "DOMAIN/FETCH_ALL_DOMAIN_LIST";
export const SAVE_ALL_DOMAIN_LIST = "DOMAIN/SAVE_ALL_DOMAIN_LIST";
export const FETCH_DOMAIN_RENEWAL_DETAILS =
  "DOMAIN/FETCH_DOMAIN_RENEWAL_DETAILS";
export const SAVE_DOMAIN_RENEWAL_DETAILS = "DOMAIN/SAVE_DOMAIN_RENEWAL_DETAILS";
export const GET_DOMAIN_BANNER_INFO = "DOMAIN/GET_DOMAIN_BANNER_INFO";
export const SET_DOMAIN_BANNER_INFO = "DOMAIN/SET_DOMAIN_BANNER_INFO";

//ADS
export const FETCH_ADS_LIST = "ADS/FETCH_ADS_LIST";
export const SAVE_ADS_LIST = "ADS/SAVE_ADS_LIST";
export const SEARCH_AD_LOCATIONS = "ADS/SEARCH_AD_LOCATIONS";
export const SAVE_SEARCHED_AD_LOCATIONS = "ADS/SAVE_SEARCHED_AD_LOCATIONS";
export const FETCH_AD_KEYWORDS = "ADS/FETCH_AD_KEYWORDS";
export const SAVE_AD_KEYWORDS = "ADS/SAVE_AD_KEYWORDS";
export const SAVE_AD = "ADS/SAVE_AD";
export const CREATE_AD = "ADS/CREATE_AD";
export const INITIATE_AD_PURCHASE = "ADS/INITIATE_AD_PURCHASE";
export const FETCH_AD_DETAILS = "ADS/FETCH_AD_DETAILS";
export const SAVE_AD_DETAILS = "ADS/SAVE_AD_DETAILS";
export const UPDATE_AD_STATE = "ADS/UPDATE_AD_STATE";

//Workspace
export const INITIATE_WORKSPACE_PURCHASE =
  "WORKSPACE/INITIATE_WORKSPACE_PURCHASE";
export const TOGGLE_EMAIL_PURCHASED_MODAL =
  "WORKSPACE/TOGGLE_EMAIL_PURCHASED_MODAL";
export const SAVE_CREATE_ACCOUNT_INFO = "WORKSPACE/SAVE_CREATE_ACCOUNT_INFO";
export const CREATE_EMAIL_ACCOUNT = "WORKSPACE/CREATE_EMAIL_ACCOUNT";
export const GET_WORKSPACE_EMAIL_USER = "WORKSPACE/GET_WORKSPACE_EMAIL_USER";
export const SAVE_WORKSPACE_EMAIL_USER = "WORKSPACE/SAVE_WORKSPACE_EMAIL_USER";
export const GET_WORKSPACE_DETAILS = "WORKSPACE/GET_WORKSPACE_DETAILS";
export const SAVE_WORKSPACE_DETAILS = "WORKSPACE/SAVE_WORKSPACE_DETAILS";
export const SAVE_SUBSCRIPTIONWORKSPACE_DETAILS =
  "WORKSPACE/SAVE_SUBSCRIPTIONWORKSPACE_DETAILS";
export const GET_WORKSPACE_PRICE_DETAILS =
  "WORKSPACE/GET_WORKSPACE_PRICE_DETAILS";
export const SAVE_WORKSPACE_PRICE_DETAILS =
  "WORKSPACE/SAVE_WORKSPACE_PRICE_DETAILS";
export const SAVE_WORKSPACE_OFFER_PRICE =
  "WORKSPACE/SAVE_WORKSPACE_OFFER_PRICE";
export const TOGGLE_WORKSPACE_OFFER_MODAL =
  "WORKSPACE/TOGGLE_WORKSPACE_OFFER_MODAL";
export const TOGGLE_WORKSPACE_EXPIRY_WARNING_MODAL =
  "WORKSPACE/TOGGLE_WORKSPACE_EXPIRY_WARNING_MODAL";
export const RESET_WORKSPACE_OFFER_EXPIRY_TIME =
  "WORKSPACE/RESET_WORKSPACE_OFFER_EXPIRY_TIME";
export const GET_WORKSPACE_RENEWAL_DETAILS =
  "WORKSPACE/GET_WORKSPACE_RENEWAL_DETAILS";
export const INITIATE_WORKSPACE_RENEWAL =
  "WORKSPACE/INITIATE_WORKSPACE_RENEWAL";
export const SAVE_WORKSPACE_RENEWAL_DETAILS =
  "WORKSPACE/SAVE_WORKSPACE_RENEWAL_DETAILS";

//Subscription
export const PREMIUM_CALLBACK = "SUBSCRIPTION/PREMIUM_CALLBACK";
export const SET_SUBSCRIPTION_CART = "SUBSCRIPTION/SET_SUBSCRIPTION_CART";
export const INITIATE_SUBSCRIPTION_PURCHASE =
  "SUBSCRIPTION/INITIATE_SUBSCRIPTION_PURCHASE";
export const INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON =
  "SUBSCRIPTION/INITIATE_SUBSCRIPTION_PURCHASE_USING_COUPON";

// Partner program (referral)
export const JOIN_PARTNER_PROGRAM = "REFERRAL/JOIN_PARTNER_PROGRAM";
export const FETCH_PARTNER_PROGRAM_STATUS =
  "REFERRAL/FETCH_PARTNER_PROGRAM_STATUS";
export const SAVE_PARTNER_PROGRAM_STATUS =
  "REFERRAL/SAVE_PARTNER_PROGRAM_STATUS";
export const FETCH_PARTNER_PROGRAM_DETAILS =
  "REFFERAL/FETCH_PARTNER_PROGRAM_DETAILS";
export const SAVE_PARTNER_PROGRAM_DETAILS =
  "REFFERAL/SAVE_PARTNER_PROGRAM_DETAILS";
export const FETCH_MY_EARNINGS_DETAILS = "REFFERAL/FETCH_MY_EARNINGS_DETAILS";
export const SAVE_MY_EARNINGS_DETAILS = "REFFERAL/SAVE_MY_EARNINGS_DETAILS";

// Subscription Purchase Using Coupon
export const GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON =
  "COUPON/GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON";
export const SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON =
  "COUPON/SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON";

//Brand Website
export const GET_PAYMENT_DETAIL_FOR_BRAND_WEBSITE =
  "BRAND_WEBSITE/GET_PAYMENT_DETAIL_FOR_BRAND_WEBSITE";

export const SEND_DOMAIN_CALLBACK_REQUEST =
  "CALLBACKREQUEST/SEND_CALLBACK_REQUEST";

export const GET_ALL_UTILITIES_DATA = "UTILITIES/GET_ALL_UTILITIES_DATA";
export const SAVE_UTILITIES_DATA = "UTILITIES/SAVE_UTILITIES_DATA";
