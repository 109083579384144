import * as rewardsTypes from "../ActionTypes";

const initialState = {
  data: {
  },
  claimed_rewards: {}

};

const RewardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case rewardsTypes.SAVE_ALL_REWARD:
      return { ...state, data: action.data };
    case rewardsTypes.SAVE_CLAIMED_REWARDS:
      return { ...state, claimed_rewards: action.data };
    default:
      return state;
  }
};

export default RewardsReducer;
