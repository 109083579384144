import * as orderTypes from "../ActionTypes";

export function createOrder(data) {
  return {
    type: orderTypes.CREATE_ORDER,
    data,
  };
}

export function saveOrderInfo(data) {
  return {
    type: orderTypes.SAVE_ORDER_INFO,
    data,
  };
}
