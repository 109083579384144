import * as workspaceActions from "../ActionTypes";

export const initiateWorkspacePurchase = (data, callback, isUpsell) => {
  return {
    type: workspaceActions.INITIATE_WORKSPACE_PURCHASE,
    data,
    callback,
    isUpsell,
  };
};

export const toggleEmailPurchasedModal = (data) => {
  return {
    type: workspaceActions.TOGGLE_EMAIL_PURCHASED_MODAL,
    data,
  };
};

export const saveCreateAccInfo = (data) => {
  return {
    type: workspaceActions.SAVE_CREATE_ACCOUNT_INFO,
    data,
  };
};

export const createEmailAccount = (data, callback) => {
  return {
    type: workspaceActions.CREATE_EMAIL_ACCOUNT,
    data,
    callback,
  };
};

export const getWorkspaceEmailUser = (data) => {
  return {
    type: workspaceActions.GET_WORKSPACE_EMAIL_USER,
    data,
  };
};

export const saveWorkspaceEmailUser = (data) => {
  return {
    type: workspaceActions.SAVE_WORKSPACE_EMAIL_USER,
    data,
  };
};

export const getWorkspaceDetails = (data, callback) => {
  return {
    type: workspaceActions.GET_WORKSPACE_DETAILS,
    data,
    callback
  };
};

export const saveWorkspaceDetails = (data) => {
  return {
    type: workspaceActions.SAVE_WORKSPACE_DETAILS,
    data,
  };
};

export const saveSubscriptionWorkspaceDetails = (data) => {
  return {
    type: workspaceActions.SAVE_SUBSCRIPTIONWORKSPACE_DETAILS,
    data,
  };
};

export const getWorkspacePriceDetails = (data, isUpsell, callback) => {
  return {
    type: workspaceActions.GET_WORKSPACE_PRICE_DETAILS,
    data,
    isUpsell,
    callback
  };
};

export const saveWorkspacePriceDetails = (data) => {
  return {
    type: workspaceActions.SAVE_WORKSPACE_PRICE_DETAILS,
    data,
  };
};
export const saveWorkspaceOfferPrice = (data) => {
  return {
    type: workspaceActions.SAVE_WORKSPACE_OFFER_PRICE,
    data,
  };
};

export const toggleWorkspaceOfferModal = (data) => {
  return {
    type: workspaceActions.TOGGLE_WORKSPACE_OFFER_MODAL,
    data,
  };
};

export const resetWorkspaceExpiryTime = (data) => {
  return {
    type: workspaceActions.RESET_WORKSPACE_OFFER_EXPIRY_TIME,
  };
};

export const toggleWorkspaceExpiryWarningModal = (data) => {
  return {
    type: workspaceActions.TOGGLE_WORKSPACE_EXPIRY_WARNING_MODAL,
    data,
  };
};

export const getWorkspaceRenewalDetails = (data, callback) => {
  return {
    type: workspaceActions.GET_WORKSPACE_RENEWAL_DETAILS,
    data,
    callback,
  };
};

export const saveWorkspaceRenewalDetails = (data) => {
  return {
    type: workspaceActions.SAVE_WORKSPACE_RENEWAL_DETAILS,
    data,
  };
};

export const initiateWorkspaceRenewal = (data, callback) => {
  return {
    type: workspaceActions.INITIATE_WORKSPACE_RENEWAL,
    data,
    callback,
  };
};