import React from "react";
import "./styles.scss";
import icon from "../../Assets/newww_loadericon.gif";
import { useSelector } from "react-redux";

export default function Loader() {
  const { isLoaderVisible } = useSelector((state) => ({
    isLoaderVisible: state.loaderReducer.isLoaderVisible,
  }));
  return isLoaderVisible ? (
    <div className="loader-wrap">
      {/* <div class="loadersmall"></div> */}
      <img src={icon} alt="" />
    </div>
  ) : null;
}
