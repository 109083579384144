import * as cartTypes from "../ActionTypes";

const initialState = {
  data: {
    items: [],
    order_type: 3
  },
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartTypes.ADD_TO_CART:
      return { ...state, data: action.data };
    default:
      return state;
  }
};

export default CartReducer;
