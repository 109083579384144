import { takeEvery, put } from "redux-saga/effects";
import apiCall from "../../Services/api";
import * as catalogTypes from "../ActionTypes";
import {
  saveCatalog,
  setAddProductModalVisible,
  addToCart,
  showLoader,
  removeLoader,
  saveCatalogCategories,
  setVariantModalVisibility,
  saveDetailedItemById,
  saveSearcheditems,
  setCatalogByStoreId,
  setCollectionCategory,
  saveCustomCatalog,
  saveGSTBillingDetails,
  saveSeoDetailsByType,
} from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import store from "./../Store";
import { ENDPOINTS } from "../../Config/apiEndpoints";
import {
  getCategoriesNameMap,
  getCategoriesNameMapIfCollections,
  getCollectionNameIdMap,
} from "../../Utils/common";
import { trackAppEvents } from "../../Utils/androidEvents";
import { SEO_RECOMMENDATION_TYPES } from "../../constants/catalog";

function* fetchCatalogListProducts(actions) {
  try {
    const catalogResponse = yield apiCall({
      method: "GET",
      url: `/dotk/catalog/getItems/${actions.data}`,
    });
    if (catalogResponse && catalogResponse.data.status) {
      if (actions.callback) {
        actions.callback(catalogResponse.data.item_categories);
      }
      yield put(saveCatalog(catalogResponse.data.item_categories));
    }
  } catch (err) {}
}

function* setCatalogItem(action) {
  try {
    yield put(showLoader());
    const apiResponse = yield apiCall({
      method: "POST",
      url: `/dotk/catalog/setItem`,
      data: action.data,
    });

    if (apiResponse && apiResponse.data.status) {
      let cartData = store.getState().cartReducer.data;
      yield put(setAddProductModalVisible(false));
      yield put(removeLoader());
      const item = {
        item_name: apiResponse.data.item.name,
        name: apiResponse.data.item.name,
        item_quantity: 1,
        discounted_price: apiResponse.data.item.discounted_price,
        item_price: apiResponse.data.item.price,
        price: apiResponse.data.item.price,
        id: apiResponse.data.item.id,
        item_type: "catalog",
      };

      cartData.items.push(item);
      yield put(addToCart({ ...cartData, items: [...cartData.items] }));

      if (action?.callback) {
        action.callback();
      }

      ToastNotifySuccess("Added Successfully!");
    } else {
      ToastNotifySuccess(apiResponse.data.message);
      yield put(removeLoader());
    }
  } catch (error) {
    ToastNotifySuccess(error.message);
    yield put(removeLoader());
  }
}

function* fetchCatalogItemsByCategoryId(actions) {
  try {
    const catalogResponse = yield apiCall({
      method: "GET",
      url: `/dotk/catalog/getItemsByCategoryId/${actions.data.store_id}?category_id=${actions.data.category.id}`,
    });
    if (catalogResponse && catalogResponse.data.status) {
      if (actions.callback) {
        actions.callback(catalogResponse.data.items);
      }
      yield put(
        saveCatalog({
          category: actions.data.category,
          items: catalogResponse.data.items || [],
        })
      );
    }
  } catch (err) {}
}

function* fetchCatalogItemsBasicDetailsByCategoryId(actions) {
  try {
    yield put(showLoader());
    const catalogResponse = yield apiCall({
      method: "GET",
      url: `/dotk/catalog/getItemsBasicDetails/${
        actions.data.store_id
      }?category_id=${actions.data.category.id}&category_type=${
        actions.data.category.tag_color ? 1 : 0
      }`,
    });
    if (catalogResponse && catalogResponse.data.status) {
      yield put(removeLoader());
      if (actions.callback) {
        actions.callback(catalogResponse.data.items);
      }
      yield put(
        saveCatalog({
          category: actions.data.category,
          items: catalogResponse.data.items || [],
        })
      );
    }
  } catch (err) {
    yield put(removeLoader());
  }
}

function* getCatalogCategories(actions) {
  try {
    yield put(showLoader());
    const categoriesResponse = yield apiCall({
      method: "GET",
      url: `/dotk/catalog/getCategories/${actions.data}`,
    });
    if (categoriesResponse && categoriesResponse.data.status) {
      yield put(removeLoader());
      yield put(
        saveCatalogCategories({
          store_categories: categoriesResponse.data.store_categories || [],
          tags: categoriesResponse.data.tags || [],
        })
      );
      if (actions.callback) {
        actions.callback(categoriesResponse.data.store_categories);
      }
    }
  } catch (err) {
    yield put(removeLoader());
  }
}

function* saveReorderdCategories(actions) {
  try {
    yield put(showLoader());
    const reorderResponse = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/products/updateCategoriesRank`,
      data: actions.data,
    });
    if (reorderResponse && reorderResponse.data.status) {
      yield put(removeLoader());
      if (actions.callback) {
        actions.callback();
      }
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to reorder, try later!");
  }
}

function* fetchCatalogItemById(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/catalog/getItem?store_id=${actions.data.store_id}&item_id=${actions.data.item_id}`,
    });
    if (response && response.data.status) {
      yield put(removeLoader());
      if (actions.isVariantData) {
        yield put(saveDetailedItemById(response.data.item));
        yield put(setVariantModalVisibility(true));
      } else {
        yield put(saveCatalog({}));
      }
      if (actions.callback) {
        actions.callback(response.data.item);
      }
    } else {
      if (actions.callback) {
        if (response.data.error_type === "not_found_error")
          ToastNotifyError(
            "Cannot fetch variants currently. Please try again later"
          );
        actions.callback(false);
      }
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to get data, try later!");
  }
}

function* saveReorderedItems(actions) {
  try {
    yield put(showLoader());
    const reorderResponse = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/products/updateItemsRanking`,
      data: actions.data,
    });
    if (reorderResponse && reorderResponse.data.status) {
      yield put(removeLoader());
      if (actions.callback) {
        actions.callback();
      }
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to reorder, try later!");
  }
}

function* fetchSearchedItems(actions) {
  try {
    const searchPageNo =
      store.getState().catalogReducer.searchedInfo.searchPageNo;
    const payload = {
      store_id: Number(actions.data.storeId),
      search_text: actions.data.searchText,
      page: searchPageNo,
    };
    yield put(showLoader());
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/catalog/searchItems`,
      data: payload,
    });
    if (response && response.data.status) {
      yield put(
        saveSearcheditems({
          items: response.data.items,
          next: response.data.next,
        })
      );
      yield put(removeLoader());
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch items, try later!");
  }
}

function* fetchCatalogByStoreId() {
  const storeId = store.getState()?.storeReducer?.data?.store?.store_id;
  yield put(showLoader());
  try {
    const catalogResponse = yield apiCall({
      method: "GET",
      url: ENDPOINTS.CATALOG.getItemsBasicDetailsByStoreId(storeId),
    });
    if (catalogResponse && catalogResponse.data.status) {
      yield put(setCatalogByStoreId(catalogResponse.data.store_items));
    }
  } catch (err) {
    ToastNotifyError("Unable to fetch items, try later!");
  } finally {
    yield put(removeLoader());
  }
}

function* fetchCollectionCatalog() {
  const storeId = store.getState()?.storeReducer?.data?.store?.store_id;
  yield put(showLoader());
  try {
    const collectionCategoryResponse = yield apiCall({
      method: "GET",
      url: ENDPOINTS.CATALOG.getCollectionCategory(storeId),
      parseToJson: true,
    });
    if (collectionCategoryResponse && collectionCategoryResponse.data.status) {
      yield put(setCollectionCategory(collectionCategoryResponse.data.data));
    }
  } catch (err) {
    ToastNotifyError("Unable to fetch items, try later!");
  } finally {
    yield put(removeLoader());
  }
}

/** call Share catalog PDF api. 
 * Payload type = categories only
 *  "store_id": 5164,
    "flag": 1,
    "items": [
        {
            "name": "",
            "category_items": {
                "potato": [
                    26888167,
                    26888083,
                    26887906,
                    26888078
                ],
                "cucumber": [
                    26888167,
                    26888083,
                    26887906,
                    26888078
                ],
            }
        }
    ]
}

Collection Activated 
{
    "store_id": 5164,
    "flag": 0,
    "items": [
        {
            "name": "Collection1",
            "category_items": {
                "potato": [
                    26888167,
                    26888083,
                    26887906,
                    26888078
                ],
                "cucumber": [
                    26888167,
                    26888083,
                    26887906,
                    26888078
                ],
                "fruits": [
                    26888167,
                    26888083,
                    26887906,
                    26888078
                ],
                "apple": [
                    26888167,
                    26888083,
                    26887906,
                    26888078
                ]
            }
        }
    ]
}
 */
function* sharePartialCatalog() {
  yield put(showLoader());
  const storeId = store.getState()?.storeReducer?.data?.store?.store_id;
  const collection_categories =
    store.getState()?.catalogReducer?.collection_categories;
  const customCatalog = store.getState()?.catalogReducer?.custom_catalog;
  const isCollection = collection_categories?.length > 0 ? 1 : 0;

  let items = [];
  let categoryIdNameMap = isCollection
    ? getCategoriesNameMapIfCollections()
    : getCategoriesNameMap();

  let collectionNameIdMap = getCollectionNameIdMap();

  if (isCollection) {
    Object.keys(customCatalog).forEach((collectionId) => {
      let collectionObject = {};
      collectionObject["name"] = collectionNameIdMap[collectionId];
      let categoryObject = {};
      Object.keys(customCatalog[collectionId]).forEach((categoryId) => {
        categoryObject[categoryIdNameMap[categoryId]] = Object.keys(
          customCatalog[collectionId][categoryId]
        );
        categoryObject[categoryIdNameMap[categoryId]] = categoryObject[
          categoryIdNameMap[categoryId]
        ].map((item) => {
          return parseInt(item);
        });
      });
      collectionObject["category_items"] = categoryObject;
      items.push(collectionObject);
    });
    /** push other collections at last */
    const firstElement = items.shift();
    items.push(firstElement);
  } else {
    let collectionObject = {};
    collectionObject["name"] = "";
    let categoryObject = {};
    Object.keys(customCatalog).forEach((categoryId) => {
      categoryObject[categoryIdNameMap[categoryId]] = Object.keys(
        customCatalog[categoryId]
      );
      categoryObject[categoryIdNameMap[categoryId]] = categoryObject[
        categoryIdNameMap[categoryId]
      ].map((item) => {
        return parseInt(item);
      });
    });
    collectionObject["category_items"] = categoryObject;
    items.push(collectionObject);
  }

  /** flag = 0 means withCollection */
  const payload = {
    store_id: storeId,
    flag: isCollection ? 0 : 1,
    items: items,
  };

  try {
    const shareCatalogResponse = yield apiCall({
      method: "POST",
      url: ENDPOINTS.CATALOG.shareCatalog,
      data: payload,
    });
    if (shareCatalogResponse && shareCatalogResponse.data.status) {
      const common = store.getState().commonReducer;
      trackAppEvents("Get Partial Catalog", {
        type: isCollection ? "collection" : "category",
        collection: isCollection ? common.collectionsSelection : "NA",
        category: common.categoriesSelection,
        product: common.productsSelection,
      });
      yield put(removeLoader());
    }
  } catch (err) {
    ToastNotifyError("Unable to fetch items, try later!");
    yield put(removeLoader());
  }
}

function* setCustomCatalog(actions) {
  yield put(saveCustomCatalog(actions.data));
}

function* getOrderCartById(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${ENDPOINTS.CART.getOrderCartByIdApi}/${actions.data.id}`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback(response?.data?.data);
    }
  } catch (err) {
    ToastNotifyError("Unable to fetch Cart Items. Please try again later");
  }
}

function* fetchItemsDetailsByItemIdsSaga(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.CATALOG.getItemsDetailsByItemIds,
      parseToJson: true,
      data: actions.data,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(response.data.data);
    } else {
      ToastNotifyError("Unable to fetch Requested Items!");
    }
  } catch (err) {
    ToastNotifyError(err.data?.message);
  }
}

function* getGSTBillingDetails(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      data: actions.data,
      parseToJson: true,
      url: ENDPOINTS.CATALOG.getGSTBillingDetails,
    });
    if (response && response?.data?.status) {
      yield put(saveGSTBillingDetails(response.data.data));
      // actions?.callback && actions?.callback();
    } else {
      yield put(saveGSTBillingDetails(null));
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* getCollectionCategoryItemData(actions) {
  try {
    const storeId = store.getState()?.storeReducer?.data?.store?.store_id;
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.CATALOG.getCollectionCategoryItemData(
        storeId,
        actions?.data?.collectionId,
        actions?.data?.categoryId
      ),
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback(response?.data?.data);
    } else {
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

function* getSeoBasicRecommendations(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      parseToJson: true,
      url: ENDPOINTS.CATALOG.seoBasicRecommendations(
        actions.data.storeId,
        actions.data.type
      ),
    });
    if (response && response?.data?.status) {
      yield put(
        saveSeoDetailsByType({
          type: actions.data.type,
          recommendations: response.data.data,
        })
      );
      actions?.callback({
        status: true,
        data: response?.data?.data
      });
    } else {
      ToastNotifyError(response?.data?.message);
      actions?.callback({
        status: false,
      });
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
    actions?.callback({
      status: false,
    });
  }
}

export default function* root() {
  yield takeEvery(catalogTypes.FETCH_CATALOG_LIST, fetchCatalogListProducts);
  yield takeEvery(catalogTypes.ADD_CATALOG_ITEM, setCatalogItem);
  yield takeEvery(
    catalogTypes.FETCH_CATALOG_ITEMS_BY_CATEGORYID,
    fetchCatalogItemsByCategoryId
  );
  yield takeEvery(
    catalogTypes.FETCH_CATALOG_ITEMS_BASIC_DETAILS_BY_CATEGORYID,
    fetchCatalogItemsBasicDetailsByCategoryId
  );
  yield takeEvery(catalogTypes.FETCH_CATALOG_CATEGORIES, getCatalogCategories);
  yield takeEvery(catalogTypes.SAVE_REORDERED_CATEGORY, saveReorderdCategories);
  yield takeEvery(catalogTypes.FETCH_CATALOG_ITEM_BY_ID, fetchCatalogItemById);
  yield takeEvery(catalogTypes.SAVE_REORDERED_ITEMS, saveReorderedItems);
  yield takeEvery(catalogTypes.FETCH_SEARCHED_ITEMS, fetchSearchedItems);
  yield takeEvery(catalogTypes.GET_CATALOG_BY_STORE_ID, fetchCatalogByStoreId);
  yield takeEvery(catalogTypes.GET_COLLECTION_CATEGORY, fetchCollectionCatalog);
  yield takeEvery(catalogTypes.SET_CUSTOM_CATLOG, setCustomCatalog);
  yield takeEvery(catalogTypes.SHARE_CATALOG, sharePartialCatalog);
  yield takeEvery(catalogTypes.GET_ORDER_CART_BY_ID, getOrderCartById);
  yield takeEvery(
    catalogTypes.FETCH_ITEMS_BY_ITEM_IDS,
    fetchItemsDetailsByItemIdsSaga
  );
  yield takeEvery(catalogTypes.GET_GST_BILLING_DETAILS, getGSTBillingDetails);
  yield takeEvery(
    catalogTypes.FETCH_COLLECTION_CATEGORY_ITEMS,
    getCollectionCategoryItemData
  );
  yield takeEvery(catalogTypes.FETCH_SEO_DETAILS_BY_TYPE, getSeoBasicRecommendations);
}
