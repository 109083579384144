import * as ActionTypes from "./../ActionTypes";

const initialState = {
  emailPurchasedModal: {
    show: false,
    count: 0,
  },
  createAccountInfo: {},
  workspaceInfo: null,
  isWorkspaceInfoLoaded: false,
  workspacePrimaryUser: {},
  workspaceUsersList: [],
  emailUser: null,
  subscriptionWorkspaceDetails: null,
  workspacePricingList: [],
  workspaceOfferPricing: [],
  workspaceOfferModalVisible: false,
  showWorkspaceExpiryWarningModal: false,
  allWorkspaceUsers: [],
  renewalDetails: [],
};

const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_EMAIL_PURCHASED_MODAL:
      return {
        ...state,
        emailPurchasedModal: {
          show: action.data.show,
          count: action.data.count || 0,
        },
      };
    case ActionTypes.SAVE_CREATE_ACCOUNT_INFO:
      return {
        ...state,
        createAccountInfo: action.data || {},
      };
    case ActionTypes.SAVE_WORKSPACE_DETAILS:
      return {
        ...state,
        workspaceInfo: action.data.workspace_info || null,
        workspacePrimaryUser: action.data.workspacePrimaryUser || {},
        workspaceUsersList: action.data.workspaceUsersList || [],
        isWorkspaceInfoLoaded: true,
        allWorkspaceUsers: action.data.allWorkspaceUsers || []
      };
    case ActionTypes.SAVE_SUBSCRIPTIONWORKSPACE_DETAILS:
      return {
        ...state,
        subscriptionWorkspaceDetails: action.data || null,
      };
    case ActionTypes.SAVE_WORKSPACE_EMAIL_USER:
      return {
        ...state,
        emailUser: action.data,
      };
    case ActionTypes.SAVE_WORKSPACE_PRICE_DETAILS:
      return {
        ...state,
        workspacePricingList: action.data.reverse(),
      };
    case ActionTypes.TOGGLE_WORKSPACE_OFFER_MODAL:
      return {
        ...state,
        workspaceOfferModalVisible: action.data,
      };
    case ActionTypes.SAVE_WORKSPACE_OFFER_PRICE:
      return {
        ...state,
        workspaceOfferPricing: action.data,
      };
    case ActionTypes.TOGGLE_WORKSPACE_EXPIRY_WARNING_MODAL:
      return {
        ...state,
        showWorkspaceExpiryWarningModal: action.data,
      };
    case ActionTypes.SAVE_WORKSPACE_RENEWAL_DETAILS: {
      return {
        ...state,
        renewalDetails: action.data,
      };
    }
    default:
      return state;
  }
};

export default workspaceReducer;
