const isEnvProduction = true;

export default {
  base_url: process.env.REACT_APP_BASE_API_URL,
  old_base_url: process.env.REACT_APP_OLD_BASE_API_URL,
  CDN_SERVER_URL: "//cdn.dotpe.in/cfe/",
  gkey: process.env.REACT_APP_GMAP_API_KEY_GEOCODE,
  geocodeURL: "https://maps.googleapis.com/maps/api/geocode/json",
  qrSourceString: "utm_source=qr",
  env: isEnvProduction,
  showroomURL: process.env.REACT_APP_THEME_PREVIEW_URL,
  webviewURL: process.env.REACT_APP_WEBVIEW_URL,
};

export const CURRENCY_SYMBOL = "₹";
