import {
  GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
  SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
} from "../ActionTypes";

export function getPaymentDetailsForSubscriptionPurchaseUsingCoupon(data) {
  return {
    type: GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
    data,
  };
}

export function setPaymentDetailsForSubscriptionPurchaseUsingCoupon(data) {
  return {
    type: SET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
    data,
  };
}
