import * as storeTypes from "../ActionTypes";

export function fetchStoreInfo(data, callback) {
  return {
    type: storeTypes.FETCH_STOREINFO,
    data,
    callback,
  };
}

export function saveStoreInfo(data) {
  return {
    type: storeTypes.SAVE_STOREINFO,
    data,
  };
}

export function updateStoreServiceFlag(data, callback=()=>{}) {
  return {
    type: storeTypes.UPDATE_STORE_SERVICE_FLAG,
    data,
    callback
  };
}

export function updateIsSetupFlag(data) {
  return {
    type: storeTypes.UPDATE_ISSETUP_FLAG,
    data,
  };
}
