import * as actionTypes from "../ActionTypes";

const initialState = {
  methods: [],
  downPaymentMethods: [],
  paymentInfo: {},
  prePaymentData: {},
  postPayment: {
    isInitialRedirect: false,
  },
  emiOptionCardData: {},
  togglePaymentSuccessModal: false,
  availableUpiPaymentOptions: null,
};
const PaymentMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_PAYMENT_METHODS:
      return {
        ...state,
        methods: action.data.payment_modes || [],
        downPaymentMethods: action.data.down_payment_modes || [],
      };
    case actionTypes.SAVE_PAYMENT_INFORMATIONS:
      return { ...state, paymentInfo: action.data };
    case actionTypes.SAVE_PRE_PAYMENT_DATA:
      return { ...state, prePaymentData: action.data };
    case actionTypes.SAVE_POST_PAYMENT_DATA:
      return { ...state, postPayment: action.data };
    case actionTypes.SAVE_EMI_OPTION_FOR_CARD_DATA:
      return { ...state, emiOptionCardData: action.data };
    case actionTypes.TOGGLE_PAYMENT_SUCCESS_MODAL:
      return {
        ...state,
        togglePaymentSuccessModal:
          action?.status ?? !state.togglePaymentSuccessModal,
      };
    case actionTypes.SET_AVAILABLE_UPI_PAYMENT_OPTIONS:
      return {
        ...state,
        availableUpiPaymentOptions: action?.data,
      };
    default:
      return state;
  }
};

export default PaymentMethodsReducer;
