import { SHOW_LOADER, REMOVE_LOADER } from "../ActionTypes";

const initialState = {
  isLoaderVisible: false
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, isLoaderVisible: true };
    case REMOVE_LOADER:
      return { ...state, isLoaderVisible: false };
   
    default:
      return state;
  }
};

export default LoaderReducer;
