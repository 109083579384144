import React, { Suspense, lazy } from "react";

// NAVIGATION
import { Route, Router, Switch } from "react-router-dom";
import history from "./Utils/history";
import { ROUTES } from "./Utils/constants";
import Loader from "./Components/Loader";

const NoOrdersPage = lazy(() => import("./Containers/NoOrdersPage"));
const TnC = lazy(() => import("./Containers/TnC"));
const Home = lazy(() => import("./Containers/Home"));
const EditTemplate = lazy(() => import("./Containers/EditTemplate"));
const Rewards = lazy(() => import("./Containers/Rewards"));
const Help = lazy(() => import("./Containers/Help"));
const NoItem = lazy(() => import("./Containers/NoItem"));
const TempPage = lazy(() => import("./Containers/Temp/temp"));
const CatalogList = lazy(() => import("./Containers/CatalogList"));
const Cart = lazy(() => import("./Containers/Cart"));
const BillReceipt = lazy(() => import("./Containers/BillReceipt"));
const BillImage = lazy(() => import("./Containers/BillImage"));
const QR = lazy(() => import("./Containers/QR"));
const CreativeList = lazy(() => import("./Containers/CreativeList"));
const CreativeEdit = lazy(() => import("./Containers/CreativeEdit"));
const SocialCreativeList = lazy(() =>
  import("./Containers/SocialCreativeList")
);
const SocialCreativeListV2 = lazy(() =>
  import("./Containers/SocialCreativeListV2")
);
const ProductPromotion = lazy(() => import("./Containers/ProductPromotion"));
const ProductPromotionBestseller = lazy(() =>
  import("./Containers/ProductPromotionBestseller")
);
const ProductPromotionDiscount = lazy(() =>
  import("./Containers/ProductPromotionDiscount")
);
const Products = lazy(() => import("./Containers/Products"));
// import ProductsList from "./Containers/ProductsList";
const CategoryReorder = lazy(() => import("./Containers/CategoryReorder"));
const ThemeExplore = lazy(() => import("./Containers/ThemeExplore"));
const ThemeList = lazy(() => import("./Containers/ThemeList"));
const ThemeSearch = lazy(() => import("./Containers/ThemeSearch"));
const ThemePreview = lazy(() => import("./Containers/ThemePreview"));
const ThemeDiscover = lazy(() => import("./Containers/ThemeDiscover"));
const ThemeHistory = lazy(() => import("./Containers/ThemeHistory"));
const PaymentProcess = lazy(() => import("./Containers/paymentProcessing"));
const RedirectPayment = lazy(() => import("./Containers/RedirectPayment"));
const CardPayment = lazy(() => import("./Containers/CardPayment"));
const ThemeLoader = lazy(() => import("./Containers/ThemeLoader"));
const ThemeUsed = lazy(() => import("./Containers/ThemeUsed"));
const DomainExplore = lazy(() => import("./Containers/DomainExplore"));
const DomainSearch = lazy(() => import("./Containers/DomainSearch"));
const DomainCart = lazy(() => import("./Containers/DomainCart"));
const DomainSuccess = lazy(() => import("./Containers/DomainSuccess"));
const DomainConnectSuccess = lazy(() =>
  import("./Containers/DomainConnectSuccess")
);
const DomainHistory = lazy(() => import("./Containers/DomainHistory"));
const DomainDetails = lazy(() => import("./Containers/DomainDetails"));
const DomainConnect = lazy(() => import("./Containers/DomainConnect"));
const ItemReorder = lazy(() => import("./Containers/ItemReorder"));
const CatalogListSearch = lazy(() => import("./Containers/CatalogListSearch"));
const AdsDiscover = lazy(() => import("./Containers/AdsDiscover"));
const AdsHistory = lazy(() => import("./Containers/AdsHistory"));
const AdManager = lazy(() => import("./Containers/AdManager"));
const AdKeywordSearch = lazy(() => import("./Containers/AdKeywordSearch"));
const AdLocationSearch = lazy(() => import("./Containers/AdLocationSearch"));
const AdDetails = lazy(() => import("./Containers/AdDetails"));
const AdsStop = lazy(() => import("./Containers/AdsStop"));
const LockScreen = lazy(() => import("./Containers/LockScreen"));
const EditSocialMediaTemplate = lazy(() =>
  import("./Containers/EditSocialMediaTemplate")
);
const WorkspaceManager = lazy(() => import("./Containers/WorkspaceManager"));
const WorkspaceCreateAccount = lazy(() =>
  import("./Containers/WorkspaceCreateAccount")
);
const WorkspaceCreateIndividualAccount = lazy(() =>
  import("./Containers/WorkspaceCreateIndividualAccount")
);
const WorkspaceRenewal = lazy(() => import("./Containers/WorkspaceRenewal"));
const PartnerProgram = lazy(() => import("./Containers/PartnerProgram"));
const MyEarnings = lazy(() => import("./Containers/MyEarnings"));
const EcommerceSubscription = lazy(() =>
  import("./Containers/EcommerceSubscription")
);
const AddGrowthPack = lazy(() => import("./Containers/AddGrowthPack"));
const PremiumSelection = lazy(() => import("./Containers/PremiumSelection"));
const WorkspacePurchse = lazy(() => import("./Containers/WorkspacePurchase"));
const DomainList = lazy(() => import("./Containers/DomainList"));
const DomainRenewalDetails = lazy(() =>
  import("./Containers/DomainRenewalDetails")
);
const ThemeOffer = lazy(() => import("./Containers/ThemeOffer"));
const ActivateServiceCustomization = lazy(() =>
  import("./Containers/ActivateServiceCustomization")
);
const BussinessCard = lazy(() => import("./Containers/BussinessCards"));
const Cemi = lazy(() => import("./Containers/Cemi/Cemi"));
const ShareCatalog = lazy(() =>
  import("./Containers/ShareCatalog/ShareCatalog")
);
const ThemeUpsell = lazy(() => import("./Containers/ThemeUpsell"));
const WABAPurchaseInitiate = lazy(() =>
  import("./Containers/WABAPurchaseInitiate")
);
const SubscriptionPurchaseUsingCouponInitiate = lazy(() =>
  import("./Containers/SubscriptionPurchaseUsingCoupon")
);
const RedirectToOrder = lazy(() => import("./Containers/RedirectOrder"));
const DomainSalesSearch = lazy(() => import("./Containers/DomainSalesSearch"));
const SocialSharePurchase = lazy(() =>
  import("./Containers/SocialSharePurchase")
);
const SocialSharePurchaseSuccess = lazy(() =>
  import("./Containers/SocialSharePurchaseSuccess")
);
const BrandWebsitePaymentPage = lazy(() =>
  import("./Containers/BrandWebsitePaymentPage")
);
const BrandWebsitePaymentSuccess = lazy(() =>
  import("./Containers/BrandWebsitePaymentSuccess")
);
const RedirectionConnector = lazy(() =>
  import("./Containers/RedirectionConnector")
);
const AppUtilities = lazy(() => import("./Containers/AppUtilities"));

history.listen((location) => {
  window.scrollTo(0, 0);
});

const AppRouter = () => (
  <Router history={history}>
    <Suspense fallback={<Loader show={true} />}>
      <Switch>
        <Route exact path="/no-item" component={NoItem} />
        <Route exact path="/temp" component={TempPage} />
        <Route exact path="/noorderspage" component={NoOrdersPage} />
        <Route exact path="/tnc" component={TnC} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/edit-template" component={EditTemplate} />
        <Route exact path="/rewards" component={Rewards} />
        <Route exact path="/help" component={Help} />
        <Route exact path="/cataloglist" component={CatalogList} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/receipt" component={BillReceipt} />
        <Route exact path="/billconfirm" component={BillImage} />
        <Route exact path="/qr" component={QR} />
        <Route exact path="/" component={Home} />
        <Route exact path="/creative-edit" component={CreativeEdit} />
        <Route exact path="/creative-list" component={CreativeList} />
        <Route exact path="/theme-explore" component={ThemeExplore} />
        <Route exact path="/themes/category/:catid" component={ThemeList} />
        <Route exact path="/themes/color/:colorid" component={ThemeList} />
        <Route
          exact
          path="/theme-search/businesstype/"
          component={ThemeSearch}
        />
        <Route exact path="/theme-search/all/" component={ThemeSearch} />
        <Route exact path="/theme-preview" component={ThemePreview} />
        <Route exact path="/theme-discover" component={ThemeDiscover} />
        <Route exact path="/theme-history" component={ThemeHistory} />
        <Route exact path="/theme-used" component={ThemeUsed} />
        <Route exact path="/theme-loader" component={ThemeLoader} />
        <Route exact path="/theme-offer" component={ThemeOffer} />
        <Route exact path="/domain-explore" component={DomainExplore} />
        <Route
          exact
          path={ROUTES.DOMAIN_OPEN_SEARCH}
          component={DomainSalesSearch}
        />
        <Route
          exact
          path={ROUTES.DOMAIN_LANDING_CART}
          component={DomainSalesSearch}
        />
        <Route exact path="/domain-search" component={DomainSearch} />
        <Route exact path="/domain-cart" component={DomainCart} />
        <Route
          exact
          path={ROUTES.DOMAIN_PURCHASE_SUCCESS}
          component={DomainSalesSearch}
        />
        <Route
          exact
          path={ROUTES.DOMAIN_REQUEST_TO_CALLBACK}
          component={DomainSalesSearch}
        />
        <Route exact path="/domain-success" component={DomainSuccess} />
        <Route exact path="/domain-history" component={DomainHistory} />
        <Route exact path="/domain-details" component={DomainDetails} />
        <Route exact path="/domain-connect" component={DomainConnect} />
        <Route exact path="/domain-list" component={DomainList} />
        <Route
          exact
          path="/domain-renewal-details"
          component={DomainRenewalDetails}
        />
        <Route exact path="/growth-packs" component={AddGrowthPack} />
        <Route exact path="/theme-upsell" component={ThemeUpsell} />

        <Route
          exact
          path="/domain-connect-success"
          component={DomainConnectSuccess}
        />

        {/* will remain for previous versions of app */}
        <Route
          exact
          path="/social-creative-list"
          component={SocialCreativeList}
        />
        {/* for new versions of app */}
        <Route
          exact
          path="/social-creative-listV2"
          component={SocialCreativeListV2}
        />

        {/* will remain for previous versions of app */}
        <Route exact path="/product-promotion" component={ProductPromotion} />
        {/* for new versions of app */}
        <Route
          exact
          path="/product-promotion/bestseller"
          component={ProductPromotionBestseller}
        />
        <Route
          exact
          path="/product-promotion/discount"
          component={ProductPromotionDiscount}
        />

        <Route exact path="/products" component={Products} />
        {/* <Route exact path="/products-list" component={ProductsList} /> */}
        <Route exact path="/category-reorder" component={CategoryReorder} />
        <Route
          exact
          path="/category/:categoryId/item-reorder"
          component={ItemReorder}
        />
        <Route exact path="/redirectpayment" component={RedirectPayment} />
        <Route exact path="/payment/card/:oid" component={CardPayment} />
        <Route exact path="/payment/cemi/:oid" component={Cemi} />
        {/** Deprecated route */}
        <Route
          exact
          path="/payment-process/:transactionID?"
          component={PaymentProcess}
        />
        <Route
          exact
          path="/payment/processing/:transactionID"
          component={PaymentProcess}
        />
        <Route exact path="/catalog-search" component={CatalogListSearch} />

        {/* Google Ads */}
        <Route exact path="/ads-discover" component={AdsDiscover} />
        <Route
          exact
          path="/ads-history/:activeTabIndex"
          component={AdsHistory}
        />
        {/* all, paused, drafts, inreview */}
        <Route exact path="/ad/step/:stepNumber" component={AdManager} />
        <Route exact path="/ad/keyword/search" component={AdKeywordSearch} />
        <Route exact path="/ad/location/search" component={AdLocationSearch} />
        <Route exact path="/ad-details/:adId" component={AdDetails} />
        <Route exact path="/ads-stop" component={AdsStop} />
        <Route exact path="/lock/:name" component={LockScreen} />
        <Route
          exact
          path="/edit-social-media-template"
          component={EditSocialMediaTemplate}
        />

        {/* <Route exact path="/workspace-buy" component={WorkspaceBuy} /> */}
        <Route
          exact
          path={ROUTES.WORKSPACE_PURCHASE}
          component={WorkspacePurchse}
        />
        <Route
          exact
          path={ROUTES.WORKSPACE_MANAGER}
          component={WorkspaceManager}
        />
        <Route
          exact
          path={ROUTES.WORKSPACE_RENEWAL}
          component={WorkspaceRenewal}
        />
        <Route
          exact
          path={ROUTES.WORKSPACE_CREATE_ACCOUNT}
          component={WorkspaceCreateAccount}
        />
        <Route
          exact
          path={ROUTES.WORKSPACE_CREATE_ACCOUNT_AFTER_INITIAL}
          component={WorkspaceCreateIndividualAccount}
        />

        {/* Referral Program */}
        <Route exact path="/partner-program" component={PartnerProgram} />
        <Route exact path="/my-earnings" component={MyEarnings} />
        <Route
          exact
          path="/ecommerce-subscription"
          component={EcommerceSubscription}
        />
        <Route exact path="/premium-selection" component={PremiumSelection} />
        <Route
          exact
          path={"/activate-service-customization"}
          component={ActivateServiceCustomization}
        />
        <Route exact path={"/business-cards"} component={BussinessCard} />
        <Route exact path={"/share-catalog"} component={ShareCatalog} />
        <Route
          exact
          path={ROUTES.WABA_PAYMENT_INITIATE}
          component={WABAPurchaseInitiate}
        />
        <Route
          exact
          path={ROUTES.SUBSCRIPTION_PAYMENT_USING_COUPONS_ROUTE}
          component={SubscriptionPurchaseUsingCouponInitiate}
        />
        <Route
          exact
          path={ROUTES.REDIRECT_TO_ORDERS_PAGE}
          component={RedirectToOrder}
        />
        <Route
          exact
          path={ROUTES.SOCIAL_SHARE_PURCHASE_PAGE}
          component={SocialSharePurchase}
        />
        <Route
          exact
          path={ROUTES.SOCIAL_SHARE_PURCHASE_SUCCESS_PAGE}
          component={SocialSharePurchaseSuccess}
        />
        <Route
          exact
          path="/brand-website-payment/:theme_category/:id"
          component={BrandWebsitePaymentPage}
        />
        <Route
          exact
          path="/brand-website-payment-success"
          component={BrandWebsitePaymentSuccess}
        />
        <Route
          exact
          path="/redirection-connector"
          component={RedirectionConnector}
        />
        <Route exact path="/app-utilities" component={AppUtilities} />
      </Switch>
    </Suspense>
  </Router>
);
export default AppRouter;
