import * as ActionTypes from "./../ActionTypes";

const initialState = {
  displayTags: {
    business_types: [],
    colors: [],
  },
  searchTags: [],
  displayThemes: [],
  isDisplayThemeHasMore: false,
  subscriptionDetails: {
    status: false,
    data: {},
  },
  pastThemes: {
    isNext: false,
    themes: [],
  },
};

const themesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SAVE_DISPLAY_TAGS:
      return { ...state, displayTags: action.data };
    case ActionTypes.SAVE_DISPLAY_THEMES:
      return {
        ...state,
        displayThemes: state.displayThemes.concat(action.data.themes),
        isDisplayThemeHasMore: action.data.isNext,
      };
    case ActionTypes.EMPTY_DISPLAY_THEMES:
      return { ...state, displayThemes: [] };
    case ActionTypes.SAVE_SEARCH_TAGS:
      return {
        ...state,
        searchTags: action.data,
      };
    case ActionTypes.SAVE_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionDetails: {
          status: action.data.status,
          data: action.data.subscription_data || {},
          domain: action.data.domain || {},
          externalDomain: action.data.externalDomain || {},
          brandWebsiteSubscription: action.data.brandWebsiteSubscription || {},
          ecomSubscription: action.data.ecomSubscription || {},
        },
      };
    case ActionTypes.SAVE_ALL_STORE_THEMES:
      return {
        ...state,
        pastThemes: action.data || [],
      };

    default:
      return state;
  }
};

export default themesReducer;
