import { takeEvery, put } from "redux-saga/effects";
import apiCall from "../../Services/api";
import * as actionTypes from "../ActionTypes";
import {
  removeLoader,
  saveDomainList,
  showLoader,
  saveSearchDomainList,
  savePrePaymentData,
  saveDomainDetails,
  saveDomainAnalytics,
  toggleDomainPurchasedModal,
  saveAllDomainList,
  saveDomainRenewalDetails,
  saveDomainCart,
  fetchDomainCart,
  saveLatestDomainPurchaseDetails,
  setDomainBannerInfo,
} from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";
import { sendDataToApp } from "../../Utils/androidEvents";
import { ENDPOINTS } from "../../Config/apiEndpoints";
import { setPrePaymentData, setPurchaseType } from "../../Utils/paymentHelper";
import { setVersionCheck } from "../../Utils/setVersionCheck";
import { parseToJson } from "../../Utils/common";
import { DOMAIN_PURCHASE } from "../../Utils/constants";
import { getAuthToken } from "../../Utils/storage";

function* searchDomain(actions) {
  try {
    setVersionCheck(
      1.74,
      112,
      sendDataToApp({
        showLoader: true,
      })
    );
    yield put(showLoader());
    const response = yield apiCall({
      method: "POST",
      data: actions.data,
      url: ENDPOINTS.DOMAIN.SEARCH_DOMAIN,
    });
    if (response && response.data.status) {
      yield put(removeLoader());
      const payload = {
        primary_result: response.data.primary_result,
        results: response.data.results,
        searchStatus: response.data.tag_status ?? null,
      };
      yield put(saveSearchDomainList(payload));
    }
    sendDataToApp({
      stopLoader: true,
    });
  } catch (err) {
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  }
}

function* searchOpenDomain(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.SEARCH_OPEN_DOMAIN(actions.data.name),
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(removeLoader());
      const payload = {
        primary_result: response.data.data.primary_result,
        results: response.data.data.results,
        searchStatus: response.data.data.tag_status ?? null,
        searchedStringBE: response.data.data?.search_text || "",
      };
      actions.data.callback &&
        actions.data.callback(response.data.status, response?.data?.data);
      yield put(saveSearchDomainList(payload));
    } else {
      if (actions?.data?.callback) {
        actions.data.callback(response?.data?.status);
      }
      ToastNotifyError(response?.data?.message);
    }
  } catch (err) {
    if (actions?.data?.callback) {
      actions.data.callback(err?.data?.status);
    }
    ToastNotifyError(err?.data?.message);
    yield put(removeLoader());
  }
}

function* defaultSearchDomain(actions) {
  setVersionCheck(
    1.74,
    112,
    sendDataToApp({
      showLoader: true,
    })
  );
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      data: actions.data,
      url: `/dotk/vm1/premium/searchDomainDefault`,
    });
    if (response && response.data.status) {
      yield put(removeLoader());
      const payload = {
        primary_result: response.data.primary_result,
        results: response.data.results,
      };
      yield put(saveDomainList(payload));
    }
    sendDataToApp({
      stopLoader: true,
    });
  } catch (err) {
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch domains!");
  }
}

function* initDomainPurchase(actions) {
  yield put(showLoader());
  setVersionCheck(
    1.74,
    112,
    sendDataToApp({
      showLoader: true,
    })
  );
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.DOMAIN.INITIATE_DOMAIN_PURCHASE,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      setPurchaseType(
        actions.data.purchase_type === 4 ? "domain-marketing" : "domain"
      );
      setPrePaymentData(response.data?.payment);
      yield put(savePrePaymentData(response.data?.payment));
      actions.callback && actions.callback(response.data.status, response.data);
    } else {
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    }
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  } catch (err) {
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  }
}

function* getDomainDetails(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/premium/latestDomain`,
      data: actions.data,
    });
    if (response && response?.data?.status) {
      yield put(saveDomainDetails(response?.data?.domain));
      yield put(saveLatestDomainPurchaseDetails(response?.data?.domain));
      actions.callback && actions.callback(true, response?.data?.domain);
      // yield put(toggleDomainPurchasedModal(true));
      //   if (response?.data?.domain.status < 6) {
      //     // history.push(`/domain-success?token=${token}&storeid=${store_id}`)
      //   } else {
      //     // history.push(`/domain-history?token=${token}&storeid=${store_id}`)
      //   }
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch domain details!");
  }
}

function* initDomainSetupPurchase(actions) {
  yield put(showLoader());
  setVersionCheck(
    1.74,
    112,
    sendDataToApp({
      showLoader: true,
    })
  );
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.DOMAIN.initiateDomainSetupPurchase(),
      data: actions.data,
    });
    if (response && response?.data?.status) {
      setPurchaseType("domain-connect");
      setPrePaymentData(response?.data?.payment);
      yield put(savePrePaymentData(response?.data?.payment));
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    }
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  } catch (err) {
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  }
}

function* getDomainAnalytics() {
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.GET_DOMAIN_ANALYTICS,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveDomainAnalytics(response?.data?.data));
    } else {
      yield put(saveDomainAnalytics({ domains: [], count: null }));
    }
  } catch (err) {}
}

function* getAllDomainList() {
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.GET_ALL_DOMAIN_LIST,
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(saveAllDomainList(response?.data?.data));
    } else {
      yield put(saveAllDomainList([]));
    }
  } catch (err) {}
}

function* getDomainRenewalDetails(action) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.getDomainRenewalDetails(action.data),
      parseToJson: true,
    });
    if (response?.data?.status) {
      yield put(saveDomainRenewalDetails(response?.data?.data));
    } else {
      yield put(saveDomainRenewalDetails({}));
    }
  } catch (err) {}
}

function* initDomainRenewal(actions) {
  yield put(showLoader());
  setVersionCheck(
    1.74,
    112,
    sendDataToApp({
      showLoader: true,
    })
  );

  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.DOMAIN.INITIATE_DOMAIN_RENEWAL,
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      setPurchaseType("domain-renewal");
      setPrePaymentData(response?.data?.data);
      yield put(savePrePaymentData(response?.data?.data));
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    } else {
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    }
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  } catch (err) {
    sendDataToApp({
      stopLoader: true,
    });
    yield put(removeLoader());
  }
}

function* fetchDomainCartInfo(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.DOMAIN_CART,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      const parsedResponse = response?.data?.data;
      const filteredItems = parsedResponse?.items?.filter(
        (item) => item?.is_available
      );
      const filteredResponse = { ...parsedResponse, items: filteredItems };
      yield put(saveDomainCart(filteredResponse));
      actions.callback &&
        actions.callback(response.data.status, filteredResponse);
    } else {
      actions.callback &&
        actions.callback(response.data.status, response?.data);
    }
  } catch (err) {
    actions.callback && actions.callback(false, {});
  }
}

function* updateDomainCart(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.DOMAIN.DOMAIN_CART,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      if (response.data?.data?.items) {
        response.data.data.items.map((item) => {
          if (!item?.is_available) {
            ToastNotifyError(
              `Error while updating cart ${item?.error_message}`
            );
          }
        });
        yield put(fetchDomainCart());
      }
      actions.callback && actions.callback(response.data.status);
    } else {
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    }
  } catch (err) {
    actions.callback && actions.callback(false);
    ToastNotifyError(
      "Error adding domain to cart, please try again later!" +
        err?.data?.message
    );
  }
}

function* initiateDomainCartPurchase(actions) {
  try {
    const response = yield apiCall({
      method: "POST",
      url: ENDPOINTS.DOMAIN.INITIATE_DOMAIN_CART_PURCHASE,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPurchaseType(DOMAIN_PURCHASE);
      window.auth_token = getAuthToken();
      setPrePaymentData(response.data?.data);
      yield put(savePrePaymentData(response.data?.data));
      actions.callback && actions.callback(response.data.status);
    } else {
      ToastNotifyError("Unable to buy domain.  Please try again later!");
    }
  } catch (err) {
    ToastNotifyError("Unable to buy domain - " + err?.data?.message);
  }
}

function* getLatestDomainPurchaseDetails(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.LATEST_DOMAIN_DETAILS,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveLatestDomainPurchaseDetails(response.data?.data));
      actions?.callback && actions.callback(response.data.status);
    }
  } catch (err) {
    ToastNotifyError(err?.data?.message);
    console.log(err);
  }
}

function* checkDomainAvailable(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.checkDomainDetails(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(removeLoader());
      actions?.callback && actions.callback(response.data.data);
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError(err?.data?.message);
  }
}

function* getDomainBannerInfo(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: ENDPOINTS.DOMAIN.getDomainBannerInfo,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      const bannerInfo = response.data?.data?.banner_info || null;
      yield put(setDomainBannerInfo(bannerInfo));
      yield put(removeLoader());
      actions?.callback && actions.callback(response.data.data);
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError(err?.data?.message);
  }
}

export default function* root() {
  yield takeEvery(actionTypes.SEARCH_DOMAIN, searchDomain);
  yield takeEvery(actionTypes.SEARCH_OPEN_DOMAIN, searchOpenDomain);
  yield takeEvery(actionTypes.DEFAULT_SEARCH_DOMAIN, defaultSearchDomain);
  yield takeEvery(actionTypes.INITIATE_DOMAIN_PURCHASE, initDomainPurchase);
  yield takeEvery(actionTypes.INITIATE_DOMAIN_RENEWAL, initDomainRenewal);
  yield takeEvery(actionTypes.GET_DOMAIN_DETAILS, getDomainDetails);
  yield takeEvery(
    actionTypes.INITIATE_DOMAIN_SETUP_PURCHASE,
    initDomainSetupPurchase
  );
  yield takeEvery(actionTypes.GET_DOMAIN_ANALYTICS, getDomainAnalytics);
  yield takeEvery(actionTypes.FETCH_ALL_DOMAIN_LIST, getAllDomainList);
  yield takeEvery(
    actionTypes.FETCH_DOMAIN_RENEWAL_DETAILS,
    getDomainRenewalDetails
  );
  yield takeEvery(actionTypes.FETCH_DOMAIN_CART, fetchDomainCartInfo);
  yield takeEvery(actionTypes.UPDATE_DOMAIN_CART, updateDomainCart);
  yield takeEvery(
    actionTypes.INITIATE_DOMAIN_CART_PURCHASE,
    initiateDomainCartPurchase
  );
  yield takeEvery(
    actionTypes.GET_LATEST_DOMAIN_PURCHASE_DETAILS,
    getLatestDomainPurchaseDetails
  );
  yield takeEvery(actionTypes.CHECK_DOMAIN_AVAILABLE, checkDomainAvailable);
  yield takeEvery(actionTypes.GET_DOMAIN_BANNER_INFO, getDomainBannerInfo);
}
