import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  fetchCatalogItemById,
  saveDetailedItemById,
  saveProductLoaderId,
  setVariantModalVisibility,
} from "../../Redux/Actions";
import { mutateVariantItemWithCartData } from "../../Utils/common";
import Modal from "../Modal";
import { ToastNotifyInfo } from "../Toast";
import VariantRadioGroup from "../VariantRadioGroup";
import closeIcon from "./../../Assets/close.png";
import "./styles.scss";

const VariantModal = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");
  const [activeVariant, setActiveVariant] = useState(-1);
  const cartData = useSelector((state) => state.cartReducer.data);
  const {
    variant: { variantModal },
    detailedItem,
    productLoaderId,
  } = useSelector((state) => state.commonReducer);

  const currentProductItem = cartData?.items?.find(
    (item) => item.id === detailedItem?.id
  );

  useEffect(() => {
    if (variantModal && detailedItem === null) {
      dispatch(
        fetchCatalogItemById(
          { store_id: store_id, item_id: productLoaderId },
          true
        )
      );
    }

    if (variantModal === false) {
      setActiveVariant(-1);
      if (
        document.getElementsByTagName("body")[0] &&
        document.getElementsByTagName("body")[0].style
      ) {
        document.getElementsByTagName("body")[0].style.overflowY = "scroll";
      }
    } else {
      if (
        document.getElementsByTagName("body")[0] &&
        document.getElementsByTagName("body")[0].style
      ) {
        document.getElementsByTagName("body")[0].style.overflowY = "hidden";
      }
    }
  }, [variantModal, detailedItem]);

  const onVariantSelect = (selectedInd, item) => {
    // if the variant's inventory is being managed & selected variant's quantity has reached it's limit then don't go further
    if (
      item.managed_inventory === 1 &&
      currentProductItem?.variants_selected?.find(
        (selectedVariant) =>
          selectedVariant.quantity === item?.available_quantity
      )
    ) {
      ToastNotifyInfo("All available units already added in cart!");
      return;
    }
    if (item.available) setActiveVariant(selectedInd);
    else ToastNotifyInfo("Variant is out of stock!");
  };

  const onClose = () => {
    dispatch(setVariantModalVisibility(false));
  };

  const onAddToCartBtnHandle = () => {
    if (activeVariant === -1) return;

    const newCart = mutateVariantItemWithCartData(
      "add",
      detailedItem,
      detailedItem.variants[activeVariant],
      cartData.items
    );
    dispatch(addToCart({ items: newCart }));
    onClose();
  };

  return (
      <Modal
        visible={variantModal}
        customStyles={{
          padding: 0,
        }}
        className="bottom-modal"
        animation={"slideUp"}
        onClose={onClose}
      >
        <div className="variantModal">
          <div className="variantModal-section" style={{ border: "none" }}>
            <span className="variantModal-closeicon" onClick={onClose}>
              <img src={closeIcon} style={{ height: 15 }} />
            </span>
            <h3>Choose One</h3>
          </div>

          <div className="variant-list">
            <VariantRadioGroup
              itemList={detailedItem?.variants || []}
              activeIdx={activeVariant}
              onChange={onVariantSelect}
              cartItem={currentProductItem}
            />
          </div>

          <div className="add-to-cart">
            <button
              style={{
                opacity: `${activeVariant === -1 ? 0.4 : 1}`,
              }}
              onClick={onAddToCartBtnHandle}
              disabled={activeVariant === -1 ? true : false}
            >
              Add to Cart
            </button>
          </div>
        </div>
      </Modal>
  );
};

export default VariantModal;
