import * as creativeTypes from "../ActionTypes";

export function fetchCreativeCategoryList(data) {
  return {
    type: creativeTypes.FETCH_CREATIVE_CATEGORY_LIST,
    data,
  };
}
export function saveCreativeCategoryList(data) {
  return {
    type: creativeTypes.SAVE_CREATIVE_CATEGORY_LIST,
    data,
  };
}
export function fetchCreativeTemplateList(data) {
  return {
    type: creativeTypes.FETCH_CREATIVE_TEMPLATE_LIST,
    data,
  };
}
export function saveCreativeTemplateList(data) {
  return {
    type: creativeTypes.SAVE_CREATIVE_TEMPLATE_LIST,
    data,
  };
}

export function fetchCreativeBackgroundList(data) {
  return {
    type: creativeTypes.FETCH_CREATIVE_BACKGROUNDS_LIST,
    data,
  };
}
export function saveCreativeBackgroundList(data) {
  return {
    type: creativeTypes.SAVE_CREATIVE_BACKGROUNDS_LIST,
    data,
  };
}

//for social creatives list

export function fetchSocialTagList(data) {
  return {
    type: creativeTypes.FETCH_SOCIAL_TAG_LIST,
    data,
  };
}
export function saveSocialTagList(data) {
  return {
    type: creativeTypes.SAVE_SOCIAL_TAG_LIST,
    data,
  };
}
export function fetchSocialTemplateList(data) {
  return {
    type: creativeTypes.FETCH_SOCIAL_TEMPLATE_LIST,
    data,
  };
}


export function emptySocialTemplateList() {
  return {
    type: creativeTypes.EMPTY_SOCIAL_TEMPLATE_LIST,
  }
}
export function saveSocialTemplateList(data) {
  return {
    type: creativeTypes.SAVE_SOCIAL_TEMPLATE_LIST,
    data,
  };
}

export function fetchFavouriteSocialTemplateList(data) {
  return {
    type: creativeTypes.FETCH_FAVOURITE_SOCIAL_TEMPLATE_LIST,
    data,
  };
}

export function updateFavouriteCreative(data, callback) {
  return {
    type: creativeTypes.UPDATE_FAVOURITE_CREATIVE,
    data,
    callback,
  };
}

export function updateFavouriteCreativeItem(data) {
  return {
    type: creativeTypes.UPDATE_FAVOURITE_CREATIVE_ITEM,
    data
  }
}

export function saveSharedTemplate(data) {
  return {
    type: creativeTypes.SAVE_SHARED_TEMPLATE,
    data,
  };
}
