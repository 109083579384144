import { all } from "redux-saga/effects";
import templates from "./templates";
import rewards from "./rewards";
import catalog from "./catalog";
import order from "./order";
import store from "./store";
import creatives from "./creatives";
import localization from "./localization";
import appEssentials from "./appEssentials";
import common from "./common";
import themes from "./themes";
import payment from "./payment";
import domain from "./domain";
import ads from "./ads";
import subscription from "./subscription";
import workspace from "./workspace";
import referral from "./referral";
import coupon from "./coupon";
import login from "./login";
import brandWebsite from "./brandWebsite"
import utilities from './utilities'

export default function* root() {
  yield all([
    templates(),
    rewards(),
    catalog(),
    order(),
    store(),
    creatives(),
    localization(),
    appEssentials(),
    common(),
    themes(),
    payment(),
    domain(),
    ads(),
    subscription(),
    workspace(),
    referral(),
    coupon(),
    login(),
    brandWebsite(),
    utilities(),
  ]);
}
