import * as cartTypes from "../ActionTypes";

const initialState = {
  orderid: "",
};

const OrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case cartTypes.SAVE_ORDER_INFO:
      return { ...state, orderid: action.data };
    default:
      return state;
  }
};

export default OrderReducer;
