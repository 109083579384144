import { useEffect } from "react";
import { getUrlParams } from "../Utils/getUrlParams";
import { useDispatch, useSelector } from "react-redux";
import { setAvailableUpiPaymentOptions } from "../Redux/Actions";

const useAvailablePaymentOptions = () => {
  const dispatch = useDispatch();
  const [upi_options] = getUrlParams(["upi_options"]);
  const upiOptionsParsedData = JSON.parse(upi_options);

  useEffect(() => {
    if (upiOptionsParsedData && Array.isArray(upiOptionsParsedData)) {
      dispatch(setAvailableUpiPaymentOptions(upiOptionsParsedData));
    }
  }, [upi_options]);

  return null;
};

export default useAvailablePaymentOptions;
