import React from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAlertFlowSystem } from "../../Redux/Actions";
import { goBackToApp } from "../../Utils/androidEvents";

const AlertFlow = () => {
  const dispatch = useDispatch();

  const alertFlowSystemState = useSelector(
    (state) => state.commonReducer.alertFlowSystem
  );

  return alertFlowSystemState ? (
    <div className="alertFlow backdrop">
      <div className="alertFlow__body">
        <h2>Go Back</h2>
        <p>Your unsaved changes will be lost.</p>
        <p>Are you sure?</p>
        <div className="button-system">
          <button onClick={() => dispatch(setAlertFlowSystem(false))}>
            No
          </button>
          <button
            onClick={() => {
              dispatch(setAlertFlowSystem(false));
              goBackToApp();
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AlertFlow;
