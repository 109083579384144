import React from "react";
import "./styles.scss";

const VariantRadioGroup = ({ itemList, activeIdx, onChange, cartItem }) => {
  const isItemUnavailable = (el) => {
    const variantInCart = cartItem?.variants_selected?.find(
      (item) => item.variant_id === el.variant_id
    );
    return (
      el.available === 0 ||
      (el.managed_inventory === 1 &&
        variantInCart?.quantity === el.available_quantity)
    );
  };

  return (
    <div className="VariantRadioGroup">
      {itemList &&
        itemList?.map((el, ind) => {
          return (
            <article
              className={`radio-item ${
                isItemUnavailable(el) && `out-of-stock`
              }`}
              key={`variant-list-modal-${ind}`}
              onClick={() => onChange(ind, el)}
            >
              <input
                type="radio"
                name="variant-group"
                checked={ind === activeIdx}
              />
              <span>{el.variant_name}</span>
            </article>
          );
        })}
    </div>
  );
};

export default VariantRadioGroup;
