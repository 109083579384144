import { GET_ALL_UTILITIES_DATA, SAVE_UTILITIES_DATA } from "../ActionTypes";

export function getAllUtilitiesData(callback) {
  return {
    type: GET_ALL_UTILITIES_DATA,
    callback,
  };
}

export function saveAllUtilitiesData(data) {
  return {
    type: SAVE_UTILITIES_DATA,
    data,
  };
}
