import * as appTypes from "../ActionTypes";

export function saveContactList(data) {
  return {
    type: appTypes.SAVE_CONTACT_LIST,
    data,
  };
}

export function fetchHelpEssentials() {
  return {
    type: appTypes.FETCH_HELP_PAGE_ESSENTIALS
  }
}

export function saveHelpEssentials(data) {
  return {
    type: appTypes.SAVE_HELP_PAGE_ESSENTIALS,
    data
  }
}

export function fetchNoOrdersEssentials() {
  return {
    type: appTypes.FETCH_NOORDERS_PAGE_ESSENTIALS
  }
}

export function saveNoOrdersEssentials(data) {
  return {
    type: appTypes.SAVE_NOORDERS_PAGE_ESSENTIALS,
    data
  }
}