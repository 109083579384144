import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import closeIcon from "./../../Assets/close.png";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  addToCart,
  setSelectedVariantModalVisibility,
} from "../../Redux/Actions";
import { mutateVariantItemWithCartData } from "../../Utils/common";
import add from "../../Assets/add.png";
import minus from "../../Assets/minus.png";
import optionsMultiple from "../../Assets/optionsMultiple.png";
import { ACTION_TYPES } from "../ProductItem/constants";

const VairantSelectedListModal = (props) => {
  const [state, setState] = useState({ itemCartIndex: null });
  const dispatch = useDispatch();

  const {
    variant: { selectedVariantsModal },
    productLoaderId,
  } = useSelector((state) => state.commonReducer);
  const cartData = useSelector((state) => state.cartReducer.data);

  useEffect(() => {
    if (selectedVariantsModal) {
      const productIndex = cartData.items.findIndex(
        (el) => el.id === productLoaderId
      );
      setState((state) => ({ ...state, itemCartIndex: productIndex }));
      if (cartData && !cartData?.items[productIndex]) {
        onClose();
      }
    }
  }, [selectedVariantsModal, cartData, productLoaderId]);

  useEffect(() => {
    if (selectedVariantsModal) {
      if (
        document.getElementsByTagName("body")[0] &&
        document.getElementsByTagName("body")[0].style
      ) {
        document.getElementsByTagName("body")[0].style.overflowY = "hidden";
      }
    } else {
      if (
        document.getElementsByTagName("body")[0] &&
        document.getElementsByTagName("body")[0].style
      ) {
        document.getElementsByTagName("body")[0].style.overflowY = "scroll";
      }
    }
  }, [selectedVariantsModal]);

  const handleAddRemoveItem = (type, variantType) => {
    // if variant's inventory is managed and it's cart quantity reaches available quantity then return
    if (
      type === ACTION_TYPES.ADD &&
      variantType?.managed_inventory === 1 &&
      variantType?.available_quantity === variantType?.quantity
    )
      return;
    const newCart = mutateVariantItemWithCartData(
      type,
      cartData?.items[state.itemCartIndex],
      variantType,
      cartData.items
    );
    dispatch(addToCart({ items: newCart }));
  };

  const onClose = () => {
    dispatch(setSelectedVariantModalVisibility(false));
  };

  const renderATCButton = (variant) => {
    return Number(variant.quantity) > 0 ? (
      <>
        <button className="atc-btn">
          <span onClick={() => handleAddRemoveItem("remove", variant)}>
            {" "}
            <img src={minus} alt="" />{" "}
          </span>
          <small> {Number(variant.quantity)}</small>
          <span
            onClick={() => handleAddRemoveItem("add", variant)}
            style={
              variant?.managed_inventory === 1 &&
              variant?.available_quantity === variant?.quantity
                ? { filter: " grayscale(1) " }
                : null
            }
          >
            {" "}
            <img src={add} alt="" />{" "}
          </span>
        </button>
      </>
    ) : (
      <>
        <button
          className="atc-btn"
          onClick={() => handleAddRemoveItem(undefined, variant)}
        >
          <p>ADD +</p>
        </button>
      </>
    );
  };

  return (
     <Modal
        visible={selectedVariantsModal}
        customStyles={{
          paddingLeft: 0,
          paddingRight: 0,
          // maxWidth: deviceWidth <= 766 ? "100%" : 480,
        }}
        height="auto"
        animation={"slideUp"}
        className="bottom-modal variantSelectedListModal"
        onClose={onClose}
      >
        <span className="closeicon" onClick={onClose}>
          <img src={closeIcon} style={{ height: 15 }} />
        </span>

        <section className="title">Choose Item to remove</section>

        <div className="item-list">
          {cartData?.items[state.itemCartIndex]?.variants_selected?.map(
            (variant, ind) => {
              return (
                <div className="item" key={`items-variant-${ind}`}>
                  <article className="left">
                    <div className="item-name">
                      {cartData?.items[state.itemCartIndex].name}
                    </div>
                    <div className="variant-name">{variant.variant_name}</div>
                  </article>
                  <article className="right">
                    {renderATCButton(variant)}
                  </article>
                </div>
              );
            }
          )}
        </div>
      </Modal>
  );
};

export default VairantSelectedListModal;
