import * as localizedTypes from "./../ActionTypes";

export function fetchLocalizedTxt(data) {
  return {
    type: localizedTypes.FETCH_LOCALIZED_TXTS,
    data,
  };
}

export function saveLocalizedTxt(data) {
  return {
    type: localizedTypes.SAVE_LOCALIZED_TXTS,
    data,
  };
}
