import { put, takeEvery } from "redux-saga/effects";
import * as adsTypes from "../ActionTypes";
import apiCall from "../../Services/api";
import {
  removeLoader,
  saveAdsList,
  savePrePaymentData,
  saveSearchedAdLocations,
  showLoader,
  saveAdDetails,
  getAdDetails,
} from "../Actions";
import { ToastNotifyError, ToastNotifySuccess } from "../../Components/Toast";
import history from "../../Utils/history";
import { sendDataToApp } from "../../Utils/androidEvents";
import { setPrePaymentData, setPurchaseType } from "../../Utils/paymentHelper";

function* fetchAdsList(actions) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/marketing/getAdsList`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(saveAdsList(response.data.data));
      actions.callback && actions.callback(response.data.data);
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch Ads, try later!");
  }
}

function* searchAdsLocations(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/marketing/searchLocation`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(saveSearchedAdLocations(response.data.data));
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to search Locations, try later!");
  }
}

function* saveAd(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/marketing/saveAd`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response.data.status) {
      actions.callback && actions.callback(response.data.data);
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
    // ToastNotifyError("Unable to search Locations, try later!");
  }
}

function* createAd(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/marketing/createAd/${actions.data}`,
      parseToJson: true,
    });
    if (response && response.data.status) {
      ToastNotifySuccess("Ad created Successfully !");
      // actions.callback && actions.callback(response.data.data);
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
    // ToastNotifyError("Unable to search Locations, try later!");
  }
}

function* initAdPurchase(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/marketing/initiateAdPurchase`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      setPurchaseType("google-ads");
      setPrePaymentData(response?.data?.data);
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
      yield put(savePrePaymentData(response?.data?.data));
    } else {
      actions.callback &&
        actions.callback(response?.data?.status, response?.data);
    }
    sendDataToApp({
      refreshToken: true,
    });
    yield put(removeLoader());
  } catch (err) {
    sendDataToApp({
      refreshToken: true,
    });
    yield put(removeLoader());
  }
}

function* fetchAdDetails(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/marketing/getAdDetails/${actions.data}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveAdDetails(response?.data?.data));
      actions.callback &&
        actions.callback(response?.data?.status, response?.data?.data);
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch Ad details, try later!");
  }
}

function* updateAdState(actions) {
  yield put(showLoader());
  try {
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/marketing/updateAdState`,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(getAdDetails(actions.data.ad_id));
    }
    yield put(removeLoader());
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to update Ad state, try later!");
  }
}

export default function* root() {
  yield takeEvery(adsTypes.FETCH_ADS_LIST, fetchAdsList);
  yield takeEvery(adsTypes.SEARCH_AD_LOCATIONS, searchAdsLocations);
  yield takeEvery(adsTypes.SAVE_AD, saveAd);
  yield takeEvery(adsTypes.CREATE_AD, createAd);
  yield takeEvery(adsTypes.INITIATE_AD_PURCHASE, initAdPurchase);
  yield takeEvery(adsTypes.FETCH_AD_DETAILS, fetchAdDetails);
  yield takeEvery(adsTypes.UPDATE_AD_STATE, updateAdState);
}
