import { SAVE_LOCALIZED_TXTS } from "./../ActionTypes";

const initialState = {
  localizedTxts: {
    loading_txt: "Loading...",
    isAPILoaded: false,
  },
};

const localiztionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOCALIZED_TXTS:
      return { ...state, localizedTxts: action.data };
    default:
      return state;
  }
};

export default localiztionReducer;
