import { takeEvery, put } from "redux-saga/effects";
import config from "../../Config";
import apiCall from "../../Services/api";
import {
  FETCH_MY_EARNINGS_DETAILS,
  FETCH_PARTNER_PROGRAM_DETAILS,
  FETCH_PARTNER_PROGRAM_STATUS,
  JOIN_PARTNER_PROGRAM,
} from "../ActionTypes";
import {
  removeLoader,
  showLoader,
  savePartnerProgramStatus,
  savePartnerProgramDetails,
  saveMyEarningsDetails,
} from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";

function* joinPartnerProgram(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "POST",
      url: `/dotk/vm1/settings/createReseller`,
      data: actions.data,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback(response?.data?.status);
      yield put(removeLoader());
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Please try again!");
  }
}

function* fetchPartnerProgramStatus(actions) {
  try {
    yield put(showLoader());
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/settings/getResellerDetails`,
      parseToJson: true,
    });
    if (response?.data?.status) {
      actions.callback(response.data.data.status);
      yield put(savePartnerProgramStatus(response.data.data));
      yield put(removeLoader());
    } else {
      const payload = {
        email: "",
        id: 0,
        phone: "",
        status: 0,
        storeId: 0,
      };
      yield put(savePartnerProgramStatus(payload));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch status!");
  }
}

function* fetchTransactionDetailsByMonth(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/settings/getTransactionsByMonth?month=${actions.data.month}&year=${actions.data.year}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(savePartnerProgramDetails(response?.data?.data));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch program details!");
  }
}

function* fetchMyEarningsDetails(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `/dotk/vm1/settings/getMonthlyTransactions`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveMyEarningsDetails(response?.data?.data));
    }
  } catch (err) {
    yield put(removeLoader());
    ToastNotifyError("Unable to fetch earnings!");
  }
}

export default function* root() {
  yield takeEvery(JOIN_PARTNER_PROGRAM, joinPartnerProgram);
  yield takeEvery(FETCH_PARTNER_PROGRAM_STATUS, fetchPartnerProgramStatus);
  yield takeEvery(
    FETCH_PARTNER_PROGRAM_DETAILS,
    fetchTransactionDetailsByMonth
  );
  yield takeEvery(FETCH_MY_EARNINGS_DETAILS, fetchMyEarningsDetails);
}
