import * as rewardsTypes from "../ActionTypes";

export function fetchAllReward(data) {
  return {
    type: rewardsTypes.FETCH_ALL_REWARD,
    data,
  };
}

export function saveAllReward(data) {
  return {
    type: rewardsTypes.SAVE_ALL_REWARD,
    data,
  };
}

export function fetchClaimedRewards(data) {
  return {
    type: rewardsTypes.FETCH_CLAIMED_REWARDS,
    data,
  };
}

export function saveClaimedRewards(data) {
  return {
    type: rewardsTypes.SAVE_CLAIMED_REWARDS,
    data,
  };
}

export function claimReward(data, storeid) {
  return {
    type: rewardsTypes.CLAIM_REWARD,
    data,
    storeid
  };
}
