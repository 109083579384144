import { SHOW_LOADER, REMOVE_LOADER } from "../ActionTypes";

export function showLoader(data) {
  return {
    type: SHOW_LOADER,
    data
  };
}

export function removeLoader(data) {
  return {
    type: REMOVE_LOADER,
    data
  };
}
