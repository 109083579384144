import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import "./styles.scss";
import closeIcon from "./../../Assets/close.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  fetchCatalogItemById,
  fetchCatalogItemsByCategoryId,
  setChooseAndRepeatModalVisibility,
} from "../../Redux/Actions";
import { mutateVariantItemWithCartData } from "../../Utils/common";
import { ToastNotifyInfo } from "../Toast";

const VariantChooseRepeatModal = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    repeatIndex: null,
  });

  const {
    variant: { chooseRepeatModal },
    productLoaderId,
  } = useSelector((state) => state.commonReducer);

  const cartData = useSelector((state) => state.cartReducer.data);

  const urlParams = new URLSearchParams(window.location.search);
  const store_id = urlParams.get("storeid");

  useEffect(() => {
    if (chooseRepeatModal) {
      const productIndex = cartData.items.findIndex(
        (el) => el.id === productLoaderId
      );
      setState((state) => ({ ...state, repeatIndex: productIndex }));
    }
  }, [chooseRepeatModal, cartData, productLoaderId]);

  useEffect(() => {
    if (chooseRepeatModal === false) {
      if (
        document.getElementsByTagName("body")[0] &&
        document.getElementsByTagName("body")[0].style
      ) {
        document.getElementsByTagName("body")[0].style.overflowY = "scroll";
      }
    } else {
      if (
        document.getElementsByTagName("body")[0] &&
        document.getElementsByTagName("body")[0].style
      ) {
        document.getElementsByTagName("body")[0].style.overflowY = "hidden";
      }
    }
  }, [chooseRepeatModal]);

  const onClose = () => {
    dispatch(setChooseAndRepeatModalVisibility(false));
  };

  const onIWillChooseBtnHandle = () => {
    /** get the details item for this item id */
    dispatch(
      fetchCatalogItemById(
        { store_id: store_id, item_id: productLoaderId },
        true
      )
    );
    onClose();
  };

  const lastAddedVariant =
    cartData?.items[state.repeatIndex]?.variants_selected[
      cartData?.items[state.repeatIndex]?.variants_selected?.length - 1
    ];

  const isLastAddedVariantAvailable =
    lastAddedVariant?.managed_inventory === 0 ||
    (lastAddedVariant?.managed_inventory === 1 &&
      lastAddedVariant?.quantity < lastAddedVariant?.available_quantity);

  const onRepeatBtnHandle = () => {
    if (!isLastAddedVariantAvailable) {
      ToastNotifyInfo("Last selected variant is not available.");
      return;
    }
    const newCart = mutateVariantItemWithCartData(
      "repeat",
      cartData?.items[state.repeatIndex],
      cartData?.items[state.repeatIndex]?.variants_selected[
        cartData?.items[state.repeatIndex]?.variants_selected?.length - 1
      ],
      cartData?.items
    );
    dispatch(addToCart({ ...cartData, items: newCart }));
    onClose();
  };

  return (
    <Modal
        visible={chooseRepeatModal}
        customStyles={{
          paddingLeft: 0,
          paddingRight: 0,
          // maxWidth: deviceWidth <= 766 ? "1000%" : 480,
        }}
        height="auto"
        animation={"slideUp"}
        className="bottom-modal variantChooseRepeatModal"
        onClose={onClose}
      >
        <span className="closeicon" onClick={onClose}>
          <img src={closeIcon} style={{ height: 15 }} />
        </span>
        <div className="title">Choose a Variant</div>
        <div className="sub-title">Your Last Choice</div>
        <article className="variant-name">
          {/* {
          cartData?.items[state.repeatIndex]?.variants_selected[
            cartData?.items[state.repeatIndex]?.variants_selected?.length - 1
          ]?.variant_name
        } */}
        </article>
        {
          <section className="footer">
            <button className="choose_btn" onClick={onIWillChooseBtnHandle}>
              I’LL CHOOSE
            </button>
            <button
              className="repeat_btn"
              onClick={onRepeatBtnHandle}
              style={
                !isLastAddedVariantAvailable ? { backgroundColor: "gray" } : {}
              }
            >
              REPEAT
            </button>
          </section>
        }
      </Modal>
  );
};

export default VariantChooseRepeatModal;
