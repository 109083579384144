import * as catalogTypes from "../ActionTypes";

export function fetchCatalog(data, callback) {
  return {
    type: catalogTypes.FETCH_CATALOG_LIST,
    data,
    callback,
  };
}

export function saveCatalog(data) {
  return {
    type: catalogTypes.SAVE_CATALOG_LIST,
    data,
  };
}

export function addCatalogItem(data, callback) {
  return {
    type: catalogTypes.ADD_CATALOG_ITEM,
    data,
    callback,
  };
}

export function fetchCatalogCategories(data, callback) {
  return {
    type: catalogTypes.FETCH_CATALOG_CATEGORIES,
    data,
    callback,
  };
}

export function saveCatalogCategories(data) {
  return {
    type: catalogTypes.SAVE_CATALOG_CATEGORIES,
    data,
  };
}

export function fetchCatalogItemsByCategoryId(data, callback) {
  return {
    type: catalogTypes.FETCH_CATALOG_ITEMS_BY_CATEGORYID,
    data,
    callback,
  };
}

export function fetchCatalogItemsBasicDetailsByCategoryId(data, callback) {
  return {
    type: catalogTypes.FETCH_CATALOG_ITEMS_BASIC_DETAILS_BY_CATEGORYID,
    data,
    callback,
  };
}

export function saveReorderdCategory(data, callback) {
  return {
    type: catalogTypes.SAVE_REORDERED_CATEGORY,
    data,
    callback,
  };
}

export function fetchCatalogItemById(data, isVariantData, callback) {
  return {
    type: catalogTypes.FETCH_CATALOG_ITEM_BY_ID,
    data,
    isVariantData,
    callback,
  };
}

export function saveReorderedItems(data, callback) {
  return {
    type: catalogTypes.SAVE_REORDERED_ITEMS,
    data,
    callback,
  };
}

export const fetchSearchedItems = (data) => {
  return {
    type: catalogTypes.FETCH_SEARCHED_ITEMS,
    data,
  };
};

export const saveSearcheditems = (data) => {
  return {
    type: catalogTypes.SAVE_SEARCHED_ITEMS,
    data,
  };
};

export const collectionCategoryItems = (data, callback) => {
  return {
    type: catalogTypes.FETCH_COLLECTION_CATEGORY_ITEMS,
    data,
    callback,
  };
};

export const setCollectionCategoryItems = (data) => {
  return {
    type: catalogTypes.SET_COLLECTION_CATEGORY_ITEMS,
    data,
  };
};

export const updateCatalogItemsCount = (data) => {
  return {
    type: catalogTypes.UPDATE_TOTAL_CATALOG_ITEMS_COUNT,
    data,
  };
};

export const fetchCatalogByStoreId = () => {
  return {
    type: catalogTypes.GET_CATALOG_BY_STORE_ID,
  };
};

export const setCatalogByStoreId = (data) => {
  return {
    type: catalogTypes.SET_STORE_CATALOG_DATA,
    data,
  };
};

export const fetchCollectionCategory = () => {
  return {
    type: catalogTypes.GET_COLLECTION_CATEGORY,
  };
};

export const setCollectionCategory = (data) => {
  return {
    type: catalogTypes.SET_COLLECTION_CATEGORY,
    data,
  };
};

export const setCustomCatalog = (data) => {
  return {
    type: catalogTypes.SET_CUSTOM_CATLOG,
    data,
  };
};

export const saveCustomCatalog = (data) => {
  return {
    type: catalogTypes.SAVE_CUSTOM_CATLOG,
    data,
  };
};

export const shareCatalog = () => {
  return {
    type: catalogTypes.SHARE_CATALOG,
  };
};

export function getOrderCartById(data, callback) {
  return {
    type: catalogTypes.GET_ORDER_CART_BY_ID,
    data,
    callback,
  };
}

export const fetchItemsDetailsByItemIds = (data, callback) => {
  return {
    type: catalogTypes.FETCH_ITEMS_BY_ITEM_IDS,
    data,
    callback,
  };
};

export function fetchGSTBillingDetails(callback) {
  return {
    type: catalogTypes.GET_GST_BILLING_DETAILS,
    callback,
  };
}

export function saveGSTBillingDetails(data) {
  return {
    type: catalogTypes.SAVE_GST_BILLING_DETAILS,
    data,
  };
}

export function fetchSeoDetailsByType(data, callback=()=>{}) {
  return {
    type: catalogTypes.FETCH_SEO_DETAILS_BY_TYPE,
    data,
    callback,
  };
}

export function saveSeoDetailsByType(data) {
  return {
    type: catalogTypes.SAVE_SEO_DETAILS_BY_TYPE,
    data,
  };
}

export function setShowSeoSuggestions(data) {
  return {
    type: catalogTypes.SET_SHOW_SEO_RECOMMENDATIONS,
    data,
  };
}
