import { takeEvery, put } from "redux-saga/effects";
import apiCall from "../../Services/api";
import { GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON } from "../ActionTypes";
import { setPaymentDetailsForSubscriptionPurchaseUsingCoupon } from "../Actions";
import { ToastNotifyError } from "../../Components/Toast";
import { ENDPOINTS } from "../../Config/apiEndpoints";

/**
 * This function will make an get api call to get all the subscription list for purchase when the coupon is available.
 * @param actions
 */
function* getPaymentDetailsForSubscriptionPurchaseUsingCoupon(actions) {
  try {
    const response = yield apiCall({
      method: "GET",
      url: `${ENDPOINTS.getSubscriptionPaymentDetailWithCoupon}${actions.data}`,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(
        setPaymentDetailsForSubscriptionPurchaseUsingCoupon(response.data.data)
      );
    } else {
      ToastNotifyError(response.message);
    }
  } catch (err) {
    err?.data?.message && ToastNotifyError(err.data.message);
  }
}

export default function* root() {
  yield takeEvery(
    GET_PAYMENT_DETAIL_FOR_SUBSCRIPTION_USING_COUPON,
    getPaymentDetailsForSubscriptionPurchaseUsingCoupon
  );
}
