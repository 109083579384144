import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../Reducers";
import sagas from "../Sagas";
import { loadState, saveState } from "../../Utils/persistState";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const persistedState = loadState();

const middlewaresArr = [sagaMiddleware];

if (process.env.REACT_APP_CUSTOM_ENV === "DEV") {
  middlewaresArr.push(logger);
}

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(...middlewaresArr)
);

store.subscribe(() => {
  saveState({
    appReducer: store.getState().appReducer,
    storeReducer: store.getState().storeReducer,
    themesReducer: store.getState().themesReducer,
    domainReducer: store.getState().domainReducer,
  });
});

export default store;
sagaMiddleware.run(sagas);
