import * as templatesTypes from "../ActionTypes";

export function fetchWATemplates(data) {
  return {
    type: templatesTypes.FETCH_WA_TEMPLATES,
    data,
  };
}

export function saveWATemplates(data) {
  return {
    type: templatesTypes.SAVE_WA_TEMPLATES,
    data,
  };
}

export function fetchWATemplateDetails(data) {
  return {
    type: templatesTypes.FETCH_WA_TEMPLATE_DETAILS,
    data,
  };
}

export function saveWATemplateDetails(data) {
  return {
    type: templatesTypes.SAVE_WA_TEMPLATE_DETAILS,
    data,
  };
}

export function deleteWATemplate(data, history, tid) {
  return {
    type: templatesTypes.DELETE_WA_TEMPLATE,
    data,
    history,
    tid,
  };
}

export function editWATemplate(data) {
  return {
    type: templatesTypes.EDIT_WA_TEMPLATE,
    data,
  };
}

export function shareTemplate(data) {
  return {
    type: templatesTypes.SHARE_TEMPLATE,
    data,
  };
}

export function updateTemplate(data) {
  return {
    type: templatesTypes.UPDATE_TEMPLATE,
    data,
  };
}

// export function showLoader(data) {
//   return {
//     type: templatesTypes.SHOW_LOADER,
//     data,
//   };
// }

// export function removeLoader(data) {
//   return {
//     type: templatesTypes.REMOVE_LOADER,
//     data,
//   };
// }

export function getGoogleShoppingAccountDetails(data, callback) {
  return {
    type: templatesTypes.GET_GOOGLE_SHOPPING_ACCOUNT_DETAILS,
    data,
    callback,
  };
}
