import * as creativeTypes from "../ActionTypes";

const initialState = {
  businessCreatives: {
    categories: [],
    templates: [],
    backgrounds: [],
  },
  socialCreatives: {
    tags: [],
    templates: [],
  },
};

const CreativesReducer = (state = initialState, action) => {
  switch (action.type) {
    case creativeTypes.SAVE_CREATIVE_CATEGORY_LIST:
      return {
        ...state,
        businessCreatives: {
          ...state.businessCreatives,
          categories: action.data,
        },
      };
    case creativeTypes.SAVE_CREATIVE_TEMPLATE_LIST:
      return {
        ...state,
        businessCreatives: {
          ...state.businessCreatives,
          templates: action.data,
        },
      };
    case creativeTypes.SAVE_CREATIVE_BACKGROUNDS_LIST:
      return {
        ...state,
        businessCreatives: {
          ...state.businessCreatives,
          backgrounds: action.data,
        },
      };
    case creativeTypes.SAVE_SOCIAL_TAG_LIST:
      return {
        ...state,
        socialCreatives: {
          ...state.socialCreatives,
          tags: action.data,
        },
      };
    case creativeTypes.SAVE_SOCIAL_TEMPLATE_LIST:
      return {
        ...state,
        socialCreatives: {
          ...state.socialCreatives,
          templates: state.socialCreatives.templates.concat(action.data.templates),
          isNext: action.data.isNext
        },
      };
    case creativeTypes.EMPTY_SOCIAL_TEMPLATE_LIST:
      return {
        ...state,
        socialCreatives: {
          ...state.socialCreatives,
          templates: [],
          isNext: false
        }
      }
    case creativeTypes.UPDATE_FAVOURITE_CREATIVE_ITEM: 

      const tempTemplates = JSON.parse(JSON.stringify(state.socialCreatives.templates));
      const favInd = tempTemplates.findIndex(el => el.id === action.data.template_id);

      if(favInd >= 0) {
        tempTemplates[favInd]['is_favourite'] = action.data.is_favourite;
      }

      return {
        ...state,
        socialCreatives : {
          ...state.socialCreatives,
          templates: tempTemplates
        }
      }
    default:
      return state;
  }
};

export default CreativesReducer;
