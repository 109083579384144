import {
  SAVE_MY_EARNINGS_DETAILS,
  SAVE_PARTNER_PROGRAM_DETAILS,
  SAVE_PARTNER_PROGRAM_STATUS,
} from "../ActionTypes";

const initialState = {
  resellerDetails: {
    email: "",
    id: 0,
    phone: "",
    status: 0,
    storeId: 0,
  },
  isPartnerProgramStatusLoaded: false,
  myEarningsDetails: {},
  myPartnerProgramDetails: {},
};

const ReferralProgramReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PARTNER_PROGRAM_STATUS:
      return {
        ...state,
        resellerDetails: {
          ...state.resellerDetails,
          email: action.data.email,
          id: action.data.id,
          phone: action.data.phone,
          status: action.data.status,
          storeId: action.data.store_id,
        },
        isPartnerProgramStatusLoaded: true,
      };
    case SAVE_PARTNER_PROGRAM_DETAILS:
      return {
        ...state,
        myPartnerProgramDetails: action.data,
      };
    case SAVE_MY_EARNINGS_DETAILS:
      return { ...state, myEarningsDetails: action.data };
    default:
      return state;
  }
};

export default ReferralProgramReducer;
