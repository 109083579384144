import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./toast.css";
const options = {
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_CENTER,
    pauseOnHover: true,
    autoClose: 3000,
    draggable: false,
};
const optionsInfo = {
    type: toast.TYPE.INFO,
    ...options
};
const optionsSuccess = {
    type: toast.TYPE.SUCCESS,
    ...options
};
const optionsError = {
    type: toast.TYPE.ERROR,
    ...options
};
const optionsWarn = {
    type : toast.TYPE.WARNING,
    ...options
}

toast.configure({});

export const ToastNotifyInfo = (message) =>  {toast(message,optionsInfo)}
export const ToastNotifySuccess = (message) =>  {toast(message,optionsSuccess)}
export const ToastNotifyError = (message) =>  {toast(message,optionsError)}
export const ToastNotifyWarn = (message) =>  {toast(message,optionsWarn)}
export const CustomToastNotifyError = (message, options) =>  {toast(message,{...optionsWarn, ...options , toastId: message})}

