import { SEO_RECOMMENDATION_TYPES } from "../../constants/catalog";
import { getCustomCatalog } from "../../Utils/common";
import * as catalogTypes from "../ActionTypes";

const initialState = {
  categories: [],
  tags: [],
  catalog_items: {},
  showLoader: true,
  totalItems: 0,
  store_catalog_data: [],
  custom_catalog: {},
  collectionCategoryItems: {},
  gstBilling: {},
  searchedInfo: {
    isNext: 0,
    searchItems: [],
    searchPageNo: 1,
  },
  seoDetails: {
    [SEO_RECOMMENDATION_TYPES.CATEGORY]: {}
  },
  showSeoRecommendations: false,
};

const CatalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case catalogTypes.SAVE_CATALOG_LIST:
      let tempState = JSON.parse(JSON.stringify(state.catalog_items));
      if (typeof action?.data?.category?.id === "number") {
        tempState[action.data.category.id] = action.data.items;
      }
      return {
        ...state,
        catalog_items: tempState,
        totalItems: getTotalItemsLength(tempState),
      };
    case catalogTypes.SAVE_CATALOG_CATEGORIES:
      return {
        ...state,
        categories: action.data.store_categories,
        tags: action.data.tags,
        catalog_items: {},
        showLoader: false,
      };
    case catalogTypes.SAVE_SEARCHED_ITEMS:
      const newSearchedItems =
        action.data.items.length > 0
          ? [...state.searchedInfo.searchItems, ...action.data.items]
          : [];
      return {
        ...state,
        searchedInfo: {
          searchItems: newSearchedItems,
          searchPageNo: action.data.next
            ? state.searchedInfo.searchPageNo + 1
            : 1,
          isNext: action.data.next,
        },
      };
    case catalogTypes.UPDATE_TOTAL_CATALOG_ITEMS_COUNT:
      return {
        ...state,
        totalItems: action.data,
      };
    case catalogTypes.SET_STORE_CATALOG_DATA:
      return {
        ...state,
        store_catalog_data: action.data,
      };
    case catalogTypes.SET_COLLECTION_CATEGORY:
      return {
        ...state,
        collection_categories: action.data,
      };
    case catalogTypes.SET_COLLECTION_CATEGORY_ITEMS:
      const { collectionId, categoryId, items } = action.data;
      return {
        ...state,
        collectionCategoryItems: {
          ...state.collectionCategoryItems,
          [collectionId]: {
            ...(state.collectionCategoryItems[collectionId] || {}),
            [categoryId]: items,
          },
        },
      };

    case catalogTypes.SAVE_CUSTOM_CATLOG:
      return {
        ...state,
        custom_catalog: action.data,
      };
    case catalogTypes.SAVE_GST_BILLING_DETAILS:
      return { ...state, gstBilling: action.data };
    case catalogTypes.SAVE_SEO_DETAILS_BY_TYPE:
      return { ...state,
        seoDetails: {
          [action.data.type]: action.data.recommendations,
        }
      };
    case catalogTypes.SET_SHOW_SEO_RECOMMENDATIONS:
      return { ...state,
        showSeoRecommendations: action.data,
      };
    default:
      return state;
  }
};

const getTotalItemsLength = (itemPerCat) => {
  let totalItems = 0;
  const arrayOfItemsPerCategory = Object.values(itemPerCat);
  for (
    let itemIndex = 0;
    itemIndex < arrayOfItemsPerCategory.length;
    itemIndex++
  ) {
    if (totalItems >= 20) break;
    totalItems += arrayOfItemsPerCategory[itemIndex]?.length || 0;
  }
  return totalItems;
};

export default CatalogReducer;
