import * as actionTypes from "../ActionTypes";

export function searchDomain(data) {
  return {
    type: actionTypes.SEARCH_DOMAIN,
    data,
  };
}

export function searchOpenDomain(data) {
  return {
    type: actionTypes.SEARCH_OPEN_DOMAIN,
    data,
  };
}

export function defaultSearchDomain(data) {
  return {
    type: actionTypes.DEFAULT_SEARCH_DOMAIN,
    data,
  };
}

export function saveDomainList(data) {
  return {
    type: actionTypes.SAVE_DOMAIN_LIST,
    data,
  };
}

export function saveSearchDomainList(data) {
  return {
    type: actionTypes.SAVE_SEARCH_DOMAIN_LIST,
    data,
  };
}

export const initDomainPuchase = (data, callback) => {
  return {
    type: actionTypes.INITIATE_DOMAIN_PURCHASE,
    data,
    callback,
  };
};

export const initDomainRenewal = (data, callback) => {
  return {
    type: actionTypes.INITIATE_DOMAIN_RENEWAL,
    data,
    callback,
  };
};

export const getDomainDetails = (data, callback) => {
  return {
    type: actionTypes.GET_DOMAIN_DETAILS,
    data,
    callback,
  };
};

export const saveDomainDetails = (data) => {
  return {
    type: actionTypes.SAVE_DOMAIN_DETAILS,
    data,
  };
};

export const initDomainSetupPurchase = (data, callback) => {
  return {
    type: actionTypes.INITIATE_DOMAIN_SETUP_PURCHASE,
    data,
    callback,
  };
};

export const fetchDomainAnalytics = (data) => {
  return {
    type: actionTypes.GET_DOMAIN_ANALYTICS,
    data,
  };
};

export const saveDomainAnalytics = (data) => {
  return {
    type: actionTypes.SAVE_DOMAIN_ANALYTICS,
    data,
  };
};

export const toggleDomainPurchasedModal = (data) => {
  return {
    type: actionTypes.TOGGLE_DOMAIN_PURCHASED_MODAL,
    data,
  };
};

export const fetchAllDomainList = () => {
  return {
    type: actionTypes.FETCH_ALL_DOMAIN_LIST,
  };
};

export const saveAllDomainList = (data) => {
  return {
    type: actionTypes.SAVE_ALL_DOMAIN_LIST,
    data,
  };
};

export const fetchDomainRenewalDetails = (data) => {
  return {
    type: actionTypes.FETCH_DOMAIN_RENEWAL_DETAILS,
    data,
  };
};

export const saveDomainRenewalDetails = (data) => {
  return {
    type: actionTypes.SAVE_DOMAIN_RENEWAL_DETAILS,
    data,
  };
};

export const toggleDomainRenewedModal = (data) => {
  return {
    type: actionTypes.TOGGLE_DOMAIN_RENEWED_MODAL,
    data,
  };
};

export function fetchDomainCart(data, callback) {
  return {
    type: actionTypes.FETCH_DOMAIN_CART,
    data,
    callback,
  };
}

export function saveDomainCart(data) {
  return {
    type: actionTypes.SAVE_DOMAIN_CART,
    data,
  };
}

export function updateDomainCart(data, callback) {
  return {
    type: actionTypes.UPDATE_DOMAIN_CART,
    data,
    callback,
  };
}

export function initiateDomainCartPurchase(data, callback) {
  return {
    type: actionTypes.INITIATE_DOMAIN_CART_PURCHASE,
    data,
    callback,
  };
}
export function getLatestDomainPurchaseDetails(data, callback) {
  return {
    type: actionTypes.GET_LATEST_DOMAIN_PURCHASE_DETAILS,
    data,
    callback,
  };
}

export function saveLatestDomainPurchaseDetails(data) {
  return {
    type: actionTypes.SAVE_LATEST_DOMAIN_PURCHASE_DETAILS,
    data,
  };
}

export function checkDomainAvailable(data, callback) {
  return {
    type: actionTypes.CHECK_DOMAIN_AVAILABLE,
    data,
    callback,
  };
}

export function saveDomainText(data) {
  return {
    type: actionTypes.SAVE_DOMAIN_TEXT,
    data,
  };
}

export function getDomainBannerInfo(data) {
  return {
    type: actionTypes.GET_DOMAIN_BANNER_INFO,
    data,
  };
}

export function setDomainBannerInfo(data) {
  return {
    type: actionTypes.SET_DOMAIN_BANNER_INFO,
    data,
  };
}