export const DEFAULT_APP_VERSION = "0.1.0";
export function getAppOS() {
  const urlParams = new URLSearchParams(window.location.search);

  const win = window;
  let os = "webview";
  let device = "mobile";
  let version = DEFAULT_APP_VERSION;

  if (win.Android) {
    os = "android_native";
    device = "mobile";
    version = urlParams.get("app_version") || DEFAULT_APP_VERSION;
  }
  if (win.ReactNativeWebView) {
    os = "ios";
    device = "mobile";
    version = urlParams.get("version_number") || DEFAULT_APP_VERSION;
  }
  return { os, device, version };
}
