import * as commonTypes from "../ActionTypes";

export function setAddProductModalVisible(data) {
  return {
    type: commonTypes.SET_ADD_PRODUCT_MODAL_VISIBLE,
    data,
  };
}

export function recordEvent(data) {
  return {
    type: commonTypes.RECORD_ANALYTICS_EVENT,
    data,
  };
}

export function isPremiumSet(data) {
  return {
    type: commonTypes.IS_PREMIUM_SET,
    data,
  };
}

export function setAlertFlowSystem(data) {
  return {
    type: commonTypes.SET_ALERT_FLOW_SYSTEM,
    data,
  };
}

export const saveProductLoaderId = (data) => {
  return {
    type: commonTypes.SAVE_PRODUCT_LOADER_ID,
    data,
  };
};

export const saveDetailedItemById = (data) => {
  return {
    type: commonTypes.SAVE_DETAILED_ITEM_BY_ID,
    data,
  };
};
export const setVariantModalVisibility = (data) => {
  return {
    type: commonTypes.SET_VARIANT_MODEL_VISIBILITY,
    data,
  };
};

export const setChooseAndRepeatModalVisibility = (data) => {
  return {
    type: commonTypes.SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY,
    data,
  };
};

export const setSelectedVariantModalVisibility = (data) => {
  return {
    type: commonTypes.SET_SELECTED_VARIANT_MODEL_VISIBILITY,
    data,
  };
};

export const toggleLazyPayAuthModal = (data) => {
  return {
    type: commonTypes.TOGGLE_LAZYPAY_AUTH_MODAL,
    data,
  };
};

export const setCollectionsPartialSelection = () => {
  return {
    type: commonTypes.COLLECTION_PARTIAL_SELECTION,
  };
};

export const setCategoriesPartialSelection = () => {
  return {
    type: commonTypes.CATEGORY_PARTIAL_SELECTION,
  };
};

export const setProductsPartialSelection = () => {
  return {
    type: commonTypes.PRODUCT_PARTIAL_SELECTION,
  };
};

export function fetchFeatureLocks() {
  return {
    type: commonTypes.FETCH_FEATURE_LOCKS,
  };
}

export function saveFeatureLocks(data) {
  return {
    type: commonTypes.SAVE_FEATURE_LOCKS,
    data,
  };
}

export function getHelpSectionArticleRoute() {
  return {
    type: commonTypes.GET_HELP_SECTION_ROUTES,
  };
}

export function saveHelpSectionArticleRoute(data) {
  return {
    type: commonTypes.SAVE_HELP_SECTION_ROUTES,
    data,
  };
}

export function sendDomainLandingCallBackRequest(data) {
  return {
    type: commonTypes.SEND_DOMAIN_CALLBACK_REQUEST,
    data,
  };
}

export function getAppConstants(isDLPCall) {
  return {
    type: commonTypes.GET_APP_CONSTANTS,
    isDLPCall,
  };
}

export function saveAppConstants(data) {
  return {
    type: commonTypes.SAVE_APP_CONSTANTS,
    data,
  };
}
